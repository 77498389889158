export default function selectSearch(mainSelector)
{
    if(typeof mainSelector === 'string' || mainSelector instanceof String){
        mainSelector = document.querySelector(`.${mainSelector}`)
    }else{
        mainSelector = mainSelector
    }
    const optionsContainer = mainSelector.querySelector(".options-container")
    const selected = mainSelector.querySelector(".selected");
    const searchBox = mainSelector.querySelector(".search-box input");
    const optionsList = mainSelector.querySelectorAll(".option");

    selected.addEventListener("click", () => {
        optionsContainer.classList.toggle("active");

        searchBox.value = "";
        filterList("");


        if (optionsContainer.classList.contains("active")) {
          searchBox.focus();
        }
      });

      optionsList.forEach(o => {
        o.addEventListener("click", () => {
          selected.textContent = o.querySelector("label").textContent;
          optionsContainer.classList.remove("active");
          optionsList.forEach(e=>{
              e.firstChild.nextElementSibling.checked=false;
          })
          o.firstChild.nextElementSibling.checked=true;
          // here you can submit form if need
        });
      });

      searchBox.addEventListener("keyup", function(e) {
        filterList(e.target.value);
      });

      const filterList = searchTerm => {
        searchTerm = searchTerm.toLowerCase();
        optionsList.forEach(option => {
          let label = option.firstElementChild.nextElementSibling.innerText.toLowerCase();
          if (label.indexOf(searchTerm) != -1) {
            option.style.display = "block";
          } else {
            option.style.display = "none";
          }
        });
      };


}
