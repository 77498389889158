$('li.displayedUser').on('click', function (){
	let uid = $(this).data('id');
	console.log(uid);
});

 /**
 * Class User DIsplay class will also display warehouses
 * There are 2 search input text fileds in the form on each if there is button up ajax is called
 * ajax returns the list of desired resource, users or warehouses nad displays them
 * on each dislpayed resource you can click and thus add it to the form like users[] or warehouses[]
 */

class UserDisplayClass {
  
  constructor() {
    this.users = [];
		this.warehouses = [];
		this.couriers = [];
		this.remeberedUsers = [];
		this.remeberedWarehouses = [];
		this.remeberedCouriers = [];
  }
  
  displayUsers() {
	if(this.users.length != 0){
		var div = $('#displayUser');
		// remove displayed users
		div.html('');
		//put found users in the div above and make them clickable
		this.users.forEach(function(element){
			div.append(
			'<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 displayedUser" data-id = '+element.id+'>'+element.name+'</li>'
			);	
		});
		
		// passs reference to the call to variable that
		// this way we can use it inside .on function
		// $(this).data('id') is actualy data-id from above created element and has value if of user
		that = this;
		$('li.displayedUser').on('click', function (){
			// if you find users that have id that was clicked on
			// add it to the remeberedUsers
			// but first check if already is present
			if(!that.remeberedUsers.find(element => element.id == $(this).data('id'))){
				that.remeberedUsers.push(
					that.users.find(element => element.id == $(this).data('id'))
				);
			}	
			// display clicked users
			that.includeUsersInForm();
			div.html('');
			$('#searchUser').val('');
		}).bind(that, div);//bind that so that we can use this inside .on(...	
	}
  }
  
  includeUsersInForm(){
	var divHiddenInput = $('#hiddenUsersIdInput');
	var divVisibleInput = $('#visibleUsersIdInput');
	// remove displayed users
	divHiddenInput.html('');
	divVisibleInput.html('');	
	// fill two divs, one with hidden input, one with full names and emails
	// we will use full names and email to be able to remove them
	this.remeberedUsers.forEach(function(element){
		divHiddenInput.append('<input type="hidden" name = "users[]" value = "'+element.id+'">');
		divVisibleInput.append('<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 addedUser" data-id = '+element.id+'>'+element.name+'</li>');	
	});

	that = this;
	$('li.addedUser').on('click', function (){
		// just remove clicked element usin data id
		that.remeberedUsers = that.remeberedUsers.filter(element => element.id != $(this).data('id'));
		that.includeUsersInForm();
	}).bind(that);//bind that so that we can use this inside .on(...

  }
  
  // to display warehouses that were returned from api request
  displayWarehouses() {
	if(this.warehouses.length != 0){
		var div = $('#displayUser'); //we will use same div
		// remove displayed users or warehouses
		div.html('');
		//put found users in the div above and make them clickable
		this.warehouses.forEach(function(element){
			div.append(
			'<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 displayedWarehouse" data-id = '+element.id+'>'+element.name+'</li>'
			);	
		});
		
		// passs reference to the call to variable that
		// this way we can use it inside .on function
		// $(this).data('id') is actualy data-id from above created element and has value if of user
		that = this;
		$('li.displayedWarehouse').on('click', function (){
			// if you find users that have id that was clicked on
			// add it to the remeberedUsers
			// but first check if already is present
			if(!that.remeberedWarehouses.find(element => element.id == $(this).data('id'))){
				that.remeberedWarehouses.push(
					that.warehouses.find(element => element.id == $(this).data('id'))
				);
			}	
			// display clicked users
			that.includeWarehousesInForm();
			div.html('');
			$('#searchUser').val('');
		}).bind(that, div);//bind that so that we can use this inside .on(...	
	}
  }
  
   includeWarehousesInForm(){
	var divHiddenInput = $('#hiddenWarehouseIdInput');
	var divVisibleInput = $('#visibleWarehouseIdInput');
	// remove displayed users
	divHiddenInput.html('');
	divVisibleInput.html('');	
	// fill two divs, one with hidden input, one with full names and emails
	// we will use full names and email to be able to remove them
	this.remeberedWarehouses.forEach(function(element){
		divHiddenInput.append('<input type="hidden" name = "warehouses[]" value = "'+element.id+'">');
		divVisibleInput.append('<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 addedWarehouse" data-id = '+element.id+'>'+element.name+'</li>');	
	});

	that = this;
	$('li.addedWarehouse').on('click', function (){
		// just remove clicked element usin data id
		that.remeberedWarehouses = that.remeberedWarehouses.filter(element => element.id != $(this).data('id'));
		that.includeWarehousesInForm();
	}).bind(that);//bind that so that we can use this inside .on(...

  }
  
  
  
  // to display warehouses that were returned from api request
  displayCouriers() {
	if(this.couriers.length != 0){
		var div = $('#displayUser'); //we will use same div
		// remove displayed users or warehouses
		div.html('');
		//put found users in the div above and make them clickable
		this.couriers.forEach(function(element){
			div.append(
			'<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 displayedCourier" data-id = '+element.id+'>'+element.name+'</li>'
			);	
		});
		
		// passs reference to the call to variable that
		// this way we can use it inside .on function
		// $(this).data('id') is actualy data-id from above created element and has value if of user
		that = this;
		$('li.displayedCourier').on('click', function (){
			// if you find users that have id that was clicked on
			// add it to the remeberedUsers
			// but first check if already is present
			if(!that.remeberedCouriers.find(element => element.id == $(this).data('id'))){
				that.remeberedCouriers.push(
					that.couriers.find(element => element.id == $(this).data('id'))
				);
			}	
			// display clicked users
			that.includeCouriersInForm();
			div.html('');
			$('#searchUser').val('');
		}).bind(that, div);//bind that so that we can use this inside .on(...	
	}
  }
  
  includeCouriersInForm(){
	var divHiddenInput = $('#hiddenCouriersIdInput');
	var divVisibleInput = $('#visibleCouriersIdInput');
	// remove displayed users
	divHiddenInput.html('');
	divVisibleInput.html('');	
	// fill two divs, one with hidden input, one with full names and emails
	// we will use full names and email to be able to remove them
	this.remeberedCouriers.forEach(function(element){
		divHiddenInput.append('<input type="hidden" name = "couriers[]" value = "'+element.id+'">');
		divVisibleInput.append('<li class = "list-group-item list-group-item-light form-control swms-active-shadow mt-1 addedCourier" data-id = '+element.id+'>'+element.name+'</li>');	
	});

	that = this;
	$('li.addedCourier').on('click', function (){
		// just remove clicked element usin data id
		that.remeberedCouriers = that.remeberedCouriers.filter(element => element.id != $(this).data('id'));
		that.includeCouriersInForm();
	}).bind(that);//bind that so that we can use this inside .on(...

  }
  
  setUsers(data){
	this.users = data; 
  }
  setWarehouses(data){
	this.warehouses = data; 
  }
   setCouriers(data){
	this.couriers = data; 
  }
  
  setIncludedUsers(data){
	this.remeberedUsers = data; 
	this.includeUsersInForm();
  }
  setIncludedWarehouses(data){
	this.remeberedWarehouses = data; 
	this.includeWarehousesInForm();
  }
  
   setIncludedCouriers(data){
	this.remeberedCouriers = data; 
	this.includeCouriersInForm();
  }
  
}


const usdc = new UserDisplayClass();

$("#searchUser").keyup(function() {
	let data = {name:$(this).val()};
	
	if($(this).val() == ""){
		$('#displayUser').html('');
	}
	
	$.ajax({
		url: "/api/office/users/list", 
		data:data,
		success: function(result){
			$('#displayUser').html('');
			console.log(result);
			usdc.setUsers(result)
			usdc.displayUsers();
		}
	});
});

//searchWarehouse
$("#searchWarehouse").keyup(function() {
	let data = {name:$(this).val()};
	
	if($(this).val() == ""){
		$('#displayUser').html('');
	}
	
	$.ajax({
		url: "/api/office/warehouse/list", 
		data:data,
		success: function(result){
			$('#displayUser').html('');
			console.log(result);
			usdc.setWarehouses(result)
			usdc.displayWarehouses();
		}
	});
});


//searchCourier
$("#searchCourier").keyup(function() {
	let data = {name:$(this).val()};
	
	if($(this).val() == ""){
		$('#displayUser').html('');
	}
	
	$.ajax({
		url: "/api/office/courier/list", 
		data:data,
		success: function(result){
			$('#displayUser').html('');
			console.log(result);
			usdc.setCouriers(result)
			usdc.displayCouriers();
		}
	});
});

if(window.project_users){
	usdc.setIncludedUsers(window.project_users);
}

if(window.project_warehouses){
	usdc.setIncludedWarehouses(window.project_warehouses);
}

if(window.project_couriers){
	usdc.setIncludedCouriers(window.project_couriers);
}


/* submit form on select for stock page*/

$('#project_id').on('change', function() {
   var $form = $(this).closest('form');
   $form.submit();
});

$('.hrefClass').on('click', function() {
   var href =  $(this).attr('href');
   window.location.replace(href);
});



$('#coreCouriersDropdown').on('change', function() {
  $('#inputSystemName').val($(this).val()); 
  $('#inputSystemName').attr('readonly', 'true');
  
  if($(this).val() == 1){
	$('#inputSystemName').val('');
	 $('#inputSystemName').attr('readonly', 'false');
  }
});


$("#searchUserApi").keyup(function() {
	let data = {name:$(this).val()};
	$('#displayUsersApi').addClass('d-none');
	
	$.ajax({
		url: "/api/office/users/list", 
		data:data,
		success: function(result){
			var html = "";
			if(result.length > 0){
				result.forEach(function(element){
					html += '<li><a class="dropdown-item displayedUserInDropdown" data-id = '+element.id+'>' +element.name+ '</a></li>';	
				});
			}
			$('#dropDownHolders').html(html);
			$('#displayUsersApi').removeClass('d-none');
			
			$('.displayedUserInDropdown').on('click', function (){
				$('#displayUsersApi').addClass('d-none');
				$('#selectedUserDiv').html($(this).html());
				$('#searchUserApi').val('');
				$('#userId').val($(this).data('id'));
				setActiveEndPoints($(this).data('id'));
			});
		}
	});
});

// After you have found a user, we need to het his permissions and display them if he has any
function setActiveEndPoints(userId){
	// send selected user id to the api and get data back
	let data = {id:userId};
	$.ajax({
		url: "/api/office/users/list/api/permissions", 
		data:data,
		success: function(result){
			// we go throw all html elements with class permissions_input_first
			$('.permissions_input_first').each(function(index, el) {
			    // set the m all to unchecked
			    el.checked = false;	
			    // if the permisions is found in the user permision list that we got from api call
			    // set that checkbox to true
			    if(result.includes(el.id)){
			    	el.checked = true;
			    }
			});
		}
	});
}