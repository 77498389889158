if (document.body.contains(document.getElementById('client_upload_form_add_line_item_btn'))) {
    document.getElementById('client_upload_form_add_line_item_btn').addEventListener('click', function() {
        document.getElementById('line_items_div_to_copy_into').appendChild(document.getElementById('line_items_div_to_copy').cloneNode(true));

        // this is for showing div on click
        // because at original its display none
        // so it wont show
        let c = document.getElementById('line_items_div_to_copy_into').children;
        let c_l = c.length;
        let l = c[c_l - 1];
        $(l).css('display', 'flex');

        // we have to incrememnt the number at end of every single id there is
        // abnd aria-describedby
        // so
        // we will search for them and try to change that
        // ...
        // console.log(l.children[0]);

        // we are going down until we hit input-group class or modal
        going_down(l, c_l);
    });
}

function going_down(my_element, number) {
    $.each(my_element.children, function(k, v) {
        if (
            v.hasAttribute('id')
            || v.hasAttribute('aria-describedby')
            || v.hasAttribute('data-bs-target')
            || v.hasAttribute('aria-labelledby')
            ) {
            let a_id = v.getAttribute('id');
            let a_ad = v.getAttribute('aria-describedby');
            let a_dbt = v.getAttribute('data-bs-target');
            let a_al = v.getAttribute('aria-labelledby');

            let tmp_array_a_id = a_id != null ? a_id.split('_') : false;
            let tmp_array_a_ad = a_ad != null ? a_ad.split('_') : false;
            let tmp_array_a_dbt = a_dbt != null ? a_dbt.split('_') : false;
            let tmp_array_a_al = a_al != null ? a_al.split('_') : false;

            if (tmp_array_a_id) {
                tmp_array_a_id[tmp_array_a_id.length - 1] = number + 1;
                v.setAttribute('id', tmp_array_a_id.join('_'));
            }

            if (tmp_array_a_ad) {
                tmp_array_a_ad[tmp_array_a_ad.length - 1] = number + 1;
                v.setAttribute('aria-describedby', tmp_array_a_ad.join('_'));
            }

            if (tmp_array_a_dbt) {
                tmp_array_a_dbt[tmp_array_a_dbt.length - 1] = number + 1;
                v.setAttribute('data-bs-target', tmp_array_a_dbt.join('_'));
            }

            if (tmp_array_a_al) {
                tmp_array_a_al[tmp_array_a_al.length - 1] = number + 1;
                v.setAttribute('aria-labelledby', tmp_array_a_al.join('_'));
            }
        }

        going_down(v, number);
    });
}
