if (document.body.contains(document.getElementById('ddlProjectsName'))) {
document.getElementById('ddlProjectsName').addEventListener('change', function() {
        let my_data = {};
        my_data.id = this.value;

        $.ajax({
            url: "/change_user_current_project",
            method: "POST",
            data: my_data
        })
        .done(function(result) {
            // console.log(result);
            let res = JSON.parse(result);
            // console.log(res);
            window.location.reload();
        })
        .fail(function(result) {
            // console.log(result);
        });
    });
}