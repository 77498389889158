let click_for_all = document.getElementsByClassName('permissions_input_first');
let click_for_all_l = click_for_all.length;

let all_permissions = document.getElementsByClassName('permissions_input');
let all_permissions_l = all_permissions.length;

let string_to_cut = 'permissions_';

for (let i = 0; i < click_for_all_l; i++) {
	click_for_all[i].addEventListener('click', function(event) {
		let what_i_clicked = this.id;
		let to_search_with = what_i_clicked.replace(string_to_cut, '');

		for (let j = 0; j < all_permissions_l; j++) {
			let one_of_all = all_permissions[j].id;
			let to_search = one_of_all.replace(string_to_cut, '');
			
			if (to_search.indexOf(to_search_with) === 0) {
				document.getElementById(one_of_all).checked = document.getElementById(what_i_clicked).checked;
			}
		}
	});
}
