$(document).ready(function() {

    $('.print_30_orders').on('click', function(e) {

        document.getElementById('print_loader').style.display = 'block';

        let packingList = $(e.currentTarget).attr('data-packing-list');
        let order = $(e.currentTarget).attr('data-order');
        let my_url = '/kardex/orders-packing-list/' + packingList;

        if (order > 0) {
            my_url = my_url + '/' + order;
        }

        $.ajax({
            url: my_url,
            method: 'GET',
            success: function(result) {
                console.log(result)

                kd_order = result.split('-');
                kd_order_name_pl = kd_order[1] + '-' + kd_order[2];
                kd_order_name = kd_order[3];
                kd_order_year = kd_order[5];
                kd_order_month = kd_order[6];
                kd_order_day = kd_order[7];
                kd_order_url = '/storage/kardex/kardex/' + kd_order_year + '/' + kd_order_month + '/' + kd_order_day + '/' + kd_order_name_pl + '-' + kd_order_name + '/' + result + '.pdf';

                document.getElementById('myframe').src = kd_order_url;

                $('iframe#myframe').on('load', function(){
                    window.frames['myframe'].focus();
                    window.frames['myframe'].print();
                });

                document.getElementById('print_loader').style.display = 'none';
            },
            error: function(status, xhr, error) {
                console.log(xhr.error);
                console.log(xhr.status);
            }
        });
    });
});
