window.addEventListener('load', function () {

    $(function(){
        let txtarea = document.getElementById('txtarea1');
        let counter = document.getElementById('counter');
        if (txtarea != undefined) {
            txtarea.addEventListener('input', () => {
                const text = txtarea.value;
                const newLines = text.match(/\n/g);
                const count = newLines ? newLines.length + 1 : 0;
                counter.textContent = `Number of tracking numbers: ${count}`;
            
            });
        }
    });
});