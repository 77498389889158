// parcel_perform_orderReport_click

const { defaultsDeep } = require("lodash");

$('.parcel_perform_order_on_click').on('click',function(e){

    // Show blinking loading message // 
    $('#pp_data_modal').html('<h6 class="blink text-center text-secondary m-5">Data is loading... Please wait...</h6>');
    (function blink() { $('.blink').fadeOut(1000).fadeIn(500, blink); })();

    var order_id = $(e.currentTarget).attr('data-order_id');

    $.ajax({
        url:baseUrl+'/parcel_perform/orders/list/pp/'+order_id,
        type:'GET',
        dataType:'json',
        success:function(pp_respons) {

            let result = "";
            
            if (pp_respons.length > 0) {
                // Added yellow header //
                result = "<div class='border p-1 mt-3' style='background: #FDD900;'>";
                result += "<div class='row'>";
                result += "<div class='col-2 fw-bold d-flex'>Date</div>";
                result += "<div class='col-2 fw-bold d-flex'>Time</div>";
                result += "<div class='col-6 fw-bold d-flex'>Data</div>";
                result += "<div class='col-2 fw-bold d-flex'>Phase</div>";
                result += "</div>";
                result += "</div>";
            } else {
                // Added no data message //
                result = "<div class='m-5' style='background: #fff;'>";
                result += "<div class='row'>";
                result += "<div class='col-12 fw-bold d-flex align-items-center justify-content-center text-center text-danger'>";
                result += "There are no data from Parcel Perform for this order";
                result += "</div>";
                result += "</div>";
                result += "</div>";
            }

            var fromDB;
            var i = 0;

            var sorted = pp_respons.sort((a, b) => JSON.parse(a['data']).time - JSON.parse(b['data']).time);
            pp_respons = sorted.reverse();

            for (i=0; i < pp_respons.length; i++) {
                fromDB = JSON.parse(pp_respons[i]['data']);

                var dateTime1 = fromDB.time;

                // const dateTime = new Date(dateTime1);

                // var realTime = dateTime.toLocaleTimeString();

                var dateTime2 = dateTime1.split("T");
                var date = dateTime2[0];
                var time = dateTime2[1];

                var eventData = fromDB.event;
                         
                result += "<div class='border-bottom p-1'>";
                if (i < 1) {
                    result += "<div class='row'>";
                        result += "<div class='col-2 fw-bold d-flex'>" + date + "</div>";
                        result += "<div class='col-2 fw-bold d-flex'>" + time + "</div>";
                        result += "<div class='col-6 fw-bold d-flex'>" + eventData + "</div>";
                        result += "<div class='col-2 fw-bold d-flex'>" + fromDB.phase + "</div>";
                    result += "</div>";
                } else {
                    result += "<div class='row'>";
                        result += "<div class='col-2 d-flex'>" + date + "</div>";
                        result += "<div class='col-2 d-flex'>" + time + "</div>";
                        result += "<div class='col-6 d-flex'>" + eventData + "</div>";
                        result += "<div class='col-2 d-flex'>" + fromDB.phase + "</div>";
                    result += "</div>";
                }
                result += "</div>";
            }
            
            $('#pp_data_modal').append(result);
            $('#pp_data_modal').html(result);
        },
        error:function(xhr, status, error){
            // Show message in case of an error //
            $('#pp_data_modal').html('<h6 class="text-center text-danger m-5">Error while loading data. Please try again later.</h6>');
            console.log(xhr);
            console.log(status);
            console.log(error);
        }
    });
});
