$(document).ready(function () {
    // adding inputs on add click in project invoice
    $(".add_input_btn").on("click", function () {
        let i_clicked = this.id;
        let part_of_string = i_clicked.replace("add_btn", "");
        let wrapper_id = part_of_string + "wrapper";
        let html_to_clone = $("#" + wrapper_id)
            .children()
            .first()
            .clone();
        $(html_to_clone).val("");
        $("#" + wrapper_id).append(html_to_clone);
    });

    // mashing whole table to one on price
    $("#tmp_edit_from_table_form").submit(function (e) {
        e.preventDefault();

        let sort_array = [];
        $("#price_table_first_row")
            .children()
            .each(function (key, value) {
                sort_array.push($(value).text().trim());
            });

        let to_send = {};
        to_send.sorting = sort_array;
        to_send.prices = [];

        $(".edit_price_table_row").each(function (key, value) {
            let tmp = {};

            $(value)
                .children()
                .children()
                .each(function (k, v) {
                    tmp[$(v).attr("data-key")] = $(v).val();
                });

            to_send.prices.push(tmp);
        });

        $("#whole_table_in_json").val(JSON.stringify(to_send));
        $("#edit_from_table_form").submit();
    });

    // for invoice_price check_all checkbox
    // for boxable_sku check_all checkbox
    $("#check_all").on("click", function () {
        let check =
            typeof $(this).attr("checked") != "undefined" ? false : true;

        if (document.getElementsByClassName("join_price_price_id_class")) {
            $(".join_price_price_id_class").each(function (key, value) {
                $(value).attr("checked", check);
            });
        }

        if (document.getElementsByClassName("boxable_sku_checkbox")) {
            $(".boxable_sku_checkbox").each(function (key, value) {
                $(value).attr("checked", check);
            });
        }

        $(this).attr("checked", check);
    });
});

// REPORTS
$(document).ready(function () {
    // this is for step_two html
    // for filters
    // !!! START !!!
    $("#populate_invoice_form").submit(function (e) {
        $(":disabled").each(function (e) {
            $(this).removeAttr("disabled");
        });
    });

    $(".un_check_input_step_two_html").on("click", function (key, value) {
        let id = this.id;
        $("input[type=checkbox]").each(function (k, v) {
            if ($(v).attr("name") == id + "[]") {
                if ($(v).is(":checked")) {
                    $(v).prop("checked", false);
                } else {
                    $(v).prop("checked", true);
                }
            }
        });
    });

    // this is for step_two html
    // for filters
    // !!! END !!!

    recalculate_pre_table();
    color_zeros_total();
    remove_dates_from_pre_table();

    $("#to_add_btn").on("click", function () {
        let how_many_so_far = $(".pre_table_row").length;
        let to_skip = how_many_so_far - 1;

        // copy the first row
        let row_to_copy = $("#row_to_copy").html();
        $("#pre_table_tbody").append(
            '<tr class="pre_table_row">' + row_to_copy + "</tr> "
        );

        // increment id end number for math later on for each row
        $(".pre_table_row").each(function (key, value) {
            if (key > to_skip) {
                let tmp_input = $(value).children().children();
                $.each(tmp_input, function (k, v) {
                    let tmp_string;
                    let tmp_array;

                    let tmp_input_we_need; // i am special

                    if (k == 1) {
                        // second td; with the search as yuou type moment is funcking up the logic
                        // so we have to extrach input ids differently then for other children
                        let tmp_div_row = $(v).children(); // all col_mds divs inside div class row
                        let tmp_div_col_md_8 = tmp_div_row[1]; // this one has the input we need inside
                        tmp_input_we_need = $(tmp_div_col_md_8)
                            .children()
                            .first();
                        tmp_string = $(tmp_input_we_need).attr("id");

                        tmp_array  = tmp_string.split("_");
                        tmp_popped = tmp_array.pop();
                        tmp_string = tmp_array.join('_') + '_';
                                     tmp_array.push(tmp_popped);

                    } else {
                        tmp_string = $(v).attr("id");

                        tmp_array  = tmp_string.split("_");
                        tmp_popped = tmp_array.pop();
                        tmp_string = tmp_array.join('_') + '_';
                                     tmp_array.push(tmp_popped);
                    }

                    let tmp_value;

                    if (
                        tmp_array[2] == "unit" ||
                        tmp_array[2] == "date" ||
                        tmp_array[2] == "services"
                    ) {
                        tmp_value = "";
                    } else {
                        tmp_value = "0";
                    }

                    if (k == 1) {
                        $(tmp_input_we_need).attr("id", tmp_string + key); // we are adding the number; number of the row
                        $(tmp_input_we_need).attr("data-row", key);
                        $(tmp_input_we_need).attr("data-cell", tmp_array[2]);
                        $(tmp_input_we_need).val(tmp_value);
                        $(tmp_input_we_need).attr("value", tmp_value);
                    } else {
                        $(v).attr("id", tmp_string + key); // we are adding the number; number of the row
                        $(v).attr("data-row", key);
                        $(v).attr("data-cell", tmp_array[2]);
                        $(v).val(tmp_value);
                        $(v).attr("value", tmp_value);
                    }
                });

                // we are making sure every option in select is dosplay block
                $('#pre_table_services_' + key).children().each(function(key, value) {
                    $(value).css('display', 'block');
                });

                // to add function for search_through_services_on_keyup
                set_search_as_you_type_for_select();
            }
        });
    });
    if(!document.querySelector('.overbox__only') && !document.querySelector('.enable__enter')){
    $(document).on("keydown", "form", function (event) {
        return event.key != "Enter";
    });
    }
    $("#print_to_pdf").submit(function (e) {
        e.preventDefault();

        $(":disabled").each(function (e) {
            $(this).removeAttr("disabled");
        });

        // we do it like this because form generate gazilions variables and server is complainig

        let to_send = {};

        to_send.invoice = {};
        to_send.invoice.client_info = [];
        to_send.invoice.our_info = [];
        to_send.invoice.invoice_footer = [];
        to_send.invoice.invoice_date;
        to_send.invoice.invoice_number;

        to_send.pre_table = {};
        to_send.pre_table.header = [];

        to_send.orders = {};
        to_send.orders.header = [];

        to_send.total = {};
        to_send.total.name = [];
        to_send.total.value = [];

        to_send.conversion = {};
        to_send.conversion.currency;
        to_send.conversion.rate;
        to_send.conversion.total;

        to_send.date_from;
        to_send.date_to;
        to_send.number_of_returns;
        to_send.number_of_orders;
        to_send.invoice_id;

        let number_of_excluded_rows = 0;

        $.each($(".invoice_input"), function (key, value) {
            if ($(value).attr("name") == "invoice[invoice_date]") {
                to_send.invoice.invoice_date = $(value).val();
            }

            if ($(value).attr("name") == "invoice[invoice_number]") {
                to_send.invoice.invoice_number = $(value).val();
            }

            if ($(value).attr("name") == "invoice[client_info][]") {
                to_send.invoice.client_info.push($(value).val());
            }

            if ($(value).attr("name") == "invoice[our_info][]") {
                to_send.invoice.our_info.push($(value).val());
            }

            if ($(value).attr("name") == "invoice[invoice_footer][]") {
                to_send.invoice.invoice_footer.push($(value).val());
            }
        });

        $.each($(".pre_table_input"), function (key, value) {
            if ($(value).attr("name") == "pre_table[header][]") {
                to_send.pre_table.header.push($(value).val());
            }
        });
        $(to_send.pre_table.header).each(function (key, value) {
            to_send.pre_table[value] = [];
        });
        $.each($(".pre_table_input"), function (key, value) {
            $(to_send.pre_table.header).each(function (k, v) {
                if ($(value).attr("name") == "pre_table[" + v + "][]") {
                    to_send.pre_table[v].push($(value).val());
                }
            });
        });

        $.each($(".orders_input"), function (key, value) {
            if ($(value).attr("name") == "orders[header][]") {
                to_send.orders.header.push($(value).val());
            }
        });
        $(to_send.orders.header).each(function (key, value) {
            to_send.orders[value] = [];
        });
        let tmp_date = "";
        $.each($(".orders_input"), function (key, value) {
            if (
                $("#orders_total_" + $(value).attr("data-row")).hasClass(
                    "not_included"
                )
            ) {
                number_of_excluded_rows += 1;
                return true;
            }

            $(to_send.orders.header).each(function (k, v) {
                if ($(value).attr("name") == "orders[" + v + "][]") {
                    let value_to_push = $(value).val();
                    to_send.orders[v].push(value_to_push);
                }
            });
        });

        number_of_excluded_rows =
            number_of_excluded_rows / to_send.orders.header.length;

        $.each($(".total_input"), function (key, value) {
            if ($(value).attr("name") == "total[name][]") {
                to_send.total.name.push($(value).val());
            }

            if ($(value).attr("name") == "total[value][]") {
                to_send.total.value.push($(value).val());
            }
        });

        $.each($(".conversion_input"), function (key, value) {
            if ($(value).attr("name") == "conversion[currency]") {
                to_send.conversion.currency = $(value).val();
            }

            if ($(value).attr("name") == "conversion[rate]") {
                to_send.conversion.rate = $(value).val();
            }

            if ($(value).attr("name") == "conversion[total]") {
                to_send.conversion.total = $(value).val();
            }
        });

        to_send.date_from = $("#hidden_date_from_input").val();
        to_send.date_to = $("#hidden_date_to_input").val();
        to_send.number_of_returns = $("#hidden_number_of_returns_input").val();
        to_send.number_of_orders =
            $("#hidden_number_of_orders_input").val() - number_of_excluded_rows;
        to_send.invoice_id = $("#hidden_invoice_id_input").val();

        // console.log(to_send);
        // return false;

        $("#hidden_input").val(JSON.stringify(to_send));
        $("#hidden_form").submit();
    });

    $("#pre_table_tbody").on("change", ".pre_table_change", function () {
        let row = $(this).data("row");
        let unit = $(this).find(":selected").data("unit");
        let price = $(this).find(":selected").data("unit_price");

        $("#pre_table_unit_" + row).val(unit);
        $("#pre_table_unit_" + row).attr("value", unit);

        $("#pre_table_price_" + row).val(price);
        $("#pre_table_price_" + row).attr("value", price);

        search_select_closer(this.id, true);

        recalculate_pre_table();
        re_calculate_totals();
    });

    $("#pre_table_tbody").on("change", ".recalculate_pre_table", function () {
        let row = $(this).attr("data-row");
        let quantity = $("#pre_table_quantity_" + row).val();
        let price = $("#pre_table_price_" + row).val();

        let total = +quantity * +price;

        $("#pre_table_total_" + row).val(total.toFixed(2));
        $("#pre_table_total_" + row).attr("value", total.toFixed(2));

        re_calculate_totals();
    });

    $(".orders_recalculate").on("change", function () {
        let id = this.id;
        let row_number = $(this).attr("data-row");
        let calculation_type = $(this).attr("data-type");
        let cell_values = {};
        let total = 0;

        let row = $("#" + id).closest("tr.orders_row");
        $.each(row.children(), function (key, value) {
            let cell = $(value).children().attr("data-cell");

            cell_values[cell] = $(value).children().val();
        });

        if (calculation_type == "steps") {
            total = +cell_values.number_of_orders * +cell_values.price_step;
        } else if (calculation_type == "rates") {
            total =
                +cell_values.number_of_orders *
                (+cell_values.price_kg * +cell_values.weight +
                    +cell_values.price_item);
        }

        $("#orders_total_" + row_number).val(total.toFixed(2));
        $("#orders_total_" + row_number).attr("value", total.toFixed(2));

        re_calculate_totals();
    });

    $("#total_vat_cell").on("change", function () {
        re_calculate_totals();
    });

    $("#conversion_rate").on("change", function () {
        re_calculate_totals();
    });

    $(".include_exclude_row").on("click", function () {
        let row = $(this).attr("data-row");
        let show_row = $(this).attr("data-show_row");

        if (show_row > 0) {
            $(this).removeClass("bg-success");
            $(this).addClass("bg-danger");
            $(this).attr("data-show_row", "0");
        }
        else{
            $(this).removeClass("bg-danger");
            $(this).addClass("bg-success");
            $(this).attr("data-show_row", "1");
        }

        include_exclude_row(row, show_row);
        re_calculate_totals();
    });

    $("#include_exclude_all_rows").on("click", function () {
        let all_rows = $(".include_exclude_row");
        let show_row = $(this).attr("data-show_row");

        $(all_rows).each(function (key, value) {
            if (show_row > 0) {
                $(value).removeClass("bg-success");
                $(value).addClass("bg-danger");
                $(value).attr("data-show_row", "0");
                $("#total_input_distribution_only_cell").attr("value", "0.00");
            } else {
                $(value).removeClass("bg-danger");
                $(value).addClass("bg-success");
                $(value).attr("data-show_row", "1");
            }

            include_exclude_row($(value).attr("data-row"), show_row);

            //after click on X button all rows will be selected and TOTAL DISTRIBUTION NET will be 0.00
            let total_d = 0;

            if(show_row == 1){
                total_d = 0.00;
            }
            $("#total_input_distribution_only_cell").val(total_d.toFixed(2));
            $("#total_input_distribution_only_cell").attr("value", total_d.toFixed(2));

        });

        recalculate_pre_table();

        if (show_row > 0) {
            $(this).attr("data-show_row", "0");
            $(this).html("0");
        } else {
            $(this).attr("data-show_row", "1");
            $(this).html("X");
        }
    });


    $(".show_info").on("click", function () {
        if ($(".row_modal").length) {
            $(".row_modal").remove();
        }

        let to_display = {};
        to_display.date = $(this).attr("data-date");
        to_display.channel_app = $(this).attr("data-channel_app");
        to_display.channel_db = JSON.parse(
            $(this).attr("data-channel_db").replaceAll("'", '"')
        );
        to_display.country_app = $(this).attr("data-country_app");
        to_display.country_db = JSON.parse(
            $(this).attr("data-country_db").replaceAll("'", '"')
        );
        to_display.weight = $(this).attr("data-weight");
        to_display.auftrag_id = JSON.parse($(this).attr("data-auftrag_id"));
        to_display.box_format = $(this).attr("data-box_format");
        to_display.lager = $(this).attr("data-lager");

        // console.log(to_display);
        // return false;

        let string = "";
        string +=
            '<div class="modal fade row_modal" id="row_modal" tabindex="-1" aria-labelledby="row_modal_label" aria-hidden="true">';
        string +=
            '<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">';
        string += '<div class="modal-content">';
        string += '<div class="modal-header">';
        string +=
            '<h4 class="modal-title" id="row_modal_label">Order(s) data</h4> ';

        string +=
            '<button type="button" id="close_row_modal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> ';
        string += "</div> ";

        string += '<div class="modal-body">';
        string += '<table class="table table-bordered table-striped">';
        string += "<thead>";
        string += "<tr>";
        string += '<th>#</th> ';
        string += '<th>DATE</th> ';
        string += '<th>CHANNEL APP</th> ';
        string += '<th>CHANNEL DB</th> ';
        string += '<th>COUNTRY APP</th> ';
        string += '<th>COUNTRY DB</th> ';
        string += '<th>WEIGHT</th> ';
        string += '<th>AUFTRAG ID</th> ';
        string += '<th>BOXABLE / NON-BOXABLE</th> ';
        string += '<th>BONDED / NON-BONDED</th> ';
        string += "</tr> ";
        string += "</thead> ";

        string += "<tbody>";
        $(to_display.auftrag_id).each(function (key, value) {
            string += "<tr>";
            string += '<td>' + (+key + 1) + "</td> ";
            string += '<td>' + to_display.date + "</td> ";
            string +=
                '<td>' + to_display.channel_app + "</td> ";
            string +=
                '<td>' +
                to_display.channel_db[key] +
                "</td> ";
            string +=
                '<td>' + to_display.country_app + "</td> ";
            string +=
                '<td>' +
                to_display.country_db[key] +
                "</td> ";
            string +=
                '<td>' +
                parseFloat(to_display.weight).toFixed(2) +
                "</td> ";
            string += '<td>' + value + "</td> ";
            string +=
                '<td>' + to_display.box_format + "</td> ";
            string += '<td>' + to_display.lager + "</td> ";
            string += "</tr> ";
        });
        string += "</tbody> ";
        string += "</table> ";
        string += "</div> ";

        string += '<div class="modal-footer"></div> ';
        string += "</div> ";
        string += "</div> ";
        string += "</div> ";

        $("body").append(string);

        let myModal = new bootstrap.Modal(
            document.getElementById("row_modal"),
            {
                keyboard: false,
            }
        );

        myModal.show();

        $("#close_row_modal").on("click", function () {
            myModal.hide();
        });
    });

    set_search_as_you_type_for_select();
});

function set_search_as_you_type_for_select() {
    $(".search_through_services_on_keyup").each(function (key, value) {
        $(value).on("keyup", function () {
            let inputed_text = $(this).val();
            let select_tag_for_this_search_array = $(value)
                .parent()
                .siblings()
                .children()[0]; // theres div with ul
            let select_tag_for_this_search_id = $(
                select_tag_for_this_search_array
            )[0].id;

            // console.log(select_tag_for_this_search_id);

            let select_services_option_array =
                put_values_into_select_services_option_array(
                    select_tag_for_this_search_id
                );

            if (inputed_text.length > 2) {
                $.each(select_services_option_array, function (k, v) {
                    if (
                        v.text
                            .toLowerCase()
                            .indexOf(inputed_text.toLowerCase()) < 0
                    ) {
                        v.to_show = false;
                    }
                });

                show_search_select_preview(
                    select_services_option_array,
                    select_tag_for_this_search_id
                );
            }

            if (inputed_text.length < 3) {
                $.each(select_services_option_array, function (k, v) {
                    v.to_show = true;
                });

                search_select_closer(select_tag_for_this_search_id);
            }

            recreate_select_services_options(
                select_services_option_array,
                select_tag_for_this_search_id
            );
        });
    });
}

function include_exclude_row(row_id, show_row) {
    $.each($(".orders_row"), function (key, value) {
        if ($(value).attr("data-row") == row_id && show_row > 0) {
            $(value).addClass("bg bg-secondary");
            $("#orders_total_" + row_id).addClass("not_included");
        }

        if ($(value).attr("data-row") == row_id && show_row < 1) {
            $(value).removeClass("bg bg-secondary");
            $("#orders_total_" + row_id).removeClass("not_included");
        }
    });
}

function recreate_select_services_options(array_of_options, select_id) {
    let select_tag = $("#" + select_id);
    select_tag.html("");

    let string = "";
    $.each(array_of_options, function (key, value) {
        string += "<option ";
        string += 'value="' + value.value + '" ';
        if (value.data_unit) {
            string += 'data-unit="' + value.data_unit + '" ';
        }

        if (value.data_unit_price) {
            string += 'data-unit_price="' + value.data_unit_price + '" ';
        }
        if (!value.to_show) {
            string += 'style="display: none" ';
            value.is_selected = false;
        }
        if (value.is_selected) {
            string += "selected";
        }
        string += ">";
        string += value.text;
        string += "</option> ";
    });

    select_tag.html(string);
}

function show_search_select_preview(array_of_options, select_id) {
    let search_select_preview_jquery = $("#" + select_id)
        .parent()
        .siblings()
        .children()[1]; // there is div with search input
    let search_select_preview = $(search_select_preview_jquery);
    search_select_preview.html("");

    let string = "";
    $.each(array_of_options, function (key, value) {
        if (value.to_show) {
            string += "<li ";
            string += ">";
            string += value.text;
            string += "</li>";
        }
    });
    search_select_preview.html(string);
    search_select_preview.show();
}

function search_select_closer(select_id, clear_search_input = false) {
    let search_select_preview_jquery = $("#" + select_id)
        .parent()
        .siblings()
        .children()[1]; // there is div with search input
    let search_select_preview = $(search_select_preview_jquery);
    search_select_preview.hide();

    if (clear_search_input) {
        let search_input_jquery = $("#" + select_id)
            .parent()
            .siblings()
            .children()[0]; // there is div with ul
        let search_input = $(search_input_jquery);
        search_input.val("");
    }
}

function put_values_into_select_services_option_array(select_id) {
    let select_input = $("#" + select_id);
    let to_return = [];

    $.each(select_input, function (key, value) {
        if ($(value).attr("name") == "pre_table[Services][]") {
            let options = $(value).children();
            $.each(options, function (k, v) {
                let tmp_object = {};
                tmp_object.value = $(v).attr("value");
                tmp_object.text = $(v).text().trim();
                tmp_object.is_selected = $(v).is(":selected");
                tmp_object.data_unit = $(v).attr("data-unit");
                tmp_object.data_unit_price = $(v).attr("data-unit_price");
                tmp_object.to_show = true;

                to_return.push(tmp_object);
            });

            return false;
        }
    });

    return to_return;
}

function recalculate_pre_table() {
    $.each($(".recalculate_pre_table"), function (key, value) {
        let row = $(value).attr("data-row");
        let quantity = $("#pre_table_quantity_" + row).val();
        let price = $("#pre_table_price_" + row).val();

        let total = +quantity * +price;

        $("#pre_table_total_" + row).val(total.toFixed(2));
        $("#pre_table_total_" + row).attr("value", total.toFixed(2));
    });

    re_calculate_totals();
}

function re_calculate_totals() {
    let total = 0;
    let total_f = 0;
    let total_d = 0;

    // let exclude_all_rows = $('#include_exclude_all_rows').data("show_row").value;
    // console.log(exclude_all_rows);


    // $(".total_column_f").on('load',function() {

        // console.log(data_rows_El);
    $.each($(".total_column_f"), function (key, value) {
        let tmp_total = $(value).val();

        if (!$(value).hasClass("not_included")) {
            total += +tmp_total;
            total_f += +tmp_total;
        }
    });
    $.each($(".total_column_d"), function (key, value) {
        let tmp_total = $(value).val();

        if (!$(value).hasClass("not_included")) {
            total += +tmp_total;
            total_d += +tmp_total;
        }

    });

    $("#total_input_fulfilment_only_cell").val(total_f.toFixed(2));
    $("#total_input_fulfilment_only_cell").attr("value", total_f.toFixed(2));

    $("#total_input_distribution_only_cell").val(total_d.toFixed(2));
    $("#total_input_distribution_only_cell").attr("value", total_d.toFixed(2));

    $("#total_net_cell").val(total.toFixed(2));
    $("#total_net_cell").attr("value", total.toFixed(2));

    let total_vat = $("#total_vat_cell").val();
    let tmp_total_net_vat = +total + (+total * +total_vat) / 100;
    $("#total_net_vat_cell").val(tmp_total_net_vat.toFixed(2));
    $("#total_net_vat_cell").attr("value", tmp_total_net_vat.toFixed(2));

    let conversion_rate = $("#conversion_rate").val();
    let tmp_conversion_total = +tmp_total_net_vat * +conversion_rate;
    $("#conversion_total").val(tmp_conversion_total.toFixed(2));
    $("#conversion_total").attr("value", tmp_conversion_total.toFixed(2));

    color_zeros_total();
// });
}

function color_zeros_total() {
    $.each($(".total_column_d"), function (key, value) {
        if (+$(value).val() == 0 || +$(value).val() == 0.0) {
            $(value).closest("tr.orders_row").addClass("bg bg-warning");
        } else {
            $(value).closest("tr.orders_row").removeClass("bg bg-warning");
        }
    });
}

function remove_dates_from_pre_table() {
    $(".pre_table_row").each(function (key, value) {
        $.each($(value).children().children(), function (k, v) {
            if (key > 0 && $(v).attr("name") == "pre_table[Date][]") {
                $(v).val("");
                $(v).attr("value", "");
            }
        });
    });

    $(".orders_input").each(function (key, value) {
        if (
            $(value).attr("data-show_date") !== "undefined" &&
            $(value).attr("data-show_date") == 0 &&
            $(value).val() != "RETURNED"
        ) {
            $(value).css("color", "#E9ECEF");
        }
    });
}

$('#export_to_excel_plain').on('click', function() {
    // e.preventDefault();

    // $(':disabled').each(function(e) {
    // 	$(this).removeAttr('disabled');
    // });

    // we do it like this because form generate gazilions variables and server is complainig

    let to_send = {};

    to_send.invoice = {};
    to_send.invoice.client_info = [];
    to_send.invoice.our_info = [];
    to_send.invoice.invoice_footer = [];
    to_send.invoice.invoice_date;
    to_send.invoice.invoice_number;

    to_send.pre_table = {};
    to_send.pre_table.header = [];

    to_send.orders = {};
    to_send.orders.header = [];

    to_send.total = {};
    to_send.total.name = [];
    to_send.total.value = [];

    to_send.conversion = {};
    to_send.conversion.currency;
    to_send.conversion.rate;
    to_send.conversion.total;

    to_send.date_from;
    to_send.date_to;
    to_send.number_of_returns;
    to_send.number_of_orders;
    to_send.invoice_id;

    let number_of_excluded_rows = 0;

    $.each($('.invoice_input'), function(key, value) {
        if ($(value).attr('name') == 'invoice[invoice_date]') {
            to_send.invoice.invoice_date = $(value).val();
        }

        if ($(value).attr('name') == 'invoice[invoice_number]') {
            to_send.invoice.invoice_number = $(value).val();
        }

        if ($(value).attr('name') == 'invoice[client_info][]') {
            to_send.invoice.client_info.push($(value).val());
        }

        if ($(value).attr('name') == 'invoice[our_info][]') {
            to_send.invoice.our_info.push($(value).val());
        }

        if ($(value).attr('name') == 'invoice[invoice_footer][]') {
            to_send.invoice.invoice_footer.push($(value).val());
        }
    });

    $.each($('.pre_table_input'), function(key, value) {
        if ($(value).attr('name') == 'pre_table[header][]') {
            to_send.pre_table.header.push($(value).val());
        }
    });
    $(to_send.pre_table.header).each(function(key, value) {
        to_send.pre_table[value] = [];
    });
    $.each($('.pre_table_input'), function(key, value) {
        $(to_send.pre_table.header).each(function(k, v) {
            if ($(value).attr('name') == 'pre_table[' + v + '][]') {
                to_send.pre_table[v].push($(value).val());
            }
        });
    });

    $.each($('.orders_input'), function(key, value) {
        if ($(value).attr('name') == 'orders[header][]') {
            to_send.orders.header.push($(value).val());
        }
    });
    $(to_send.orders.header).each(function(key, value) {
        to_send.orders[value] = [];
    });
    let tmp_date = '';
    $.each($('.orders_input'), function(key, value) {
        if ($('#orders_total_' + $(value).attr('data-row')).hasClass('not_included')) {
            number_of_excluded_rows += 1;
            return true;
        }

        $(to_send.orders.header).each(function(k, v) {
            if ($(value).attr('name') == 'orders[' + v + '][]') {
                let value_to_push = $(value).val();
                to_send.orders[v].push(value_to_push);
            }
        });
    });

    number_of_excluded_rows = number_of_excluded_rows / to_send.orders.header.length;

    $.each($('.total_input'), function(key, value) {
        if ($(value).attr('name') == 'total[name][]') {
            to_send.total.name.push($(value).val());
        }

        if ($(value).attr('name') == 'total[value][]') {
            to_send.total.value.push($(value).val());
        }
    });

    $.each($('.conversion_input'), function(key, value) {
        if ($(value).attr('name') == 'conversion[currency]') {
            to_send.conversion.currency = $(value).val();
        }

        if ($(value).attr('name') == 'conversion[rate]') {
            to_send.conversion.rate = $(value).val();
        }

        if ($(value).attr('name') == 'conversion[total]') {
            to_send.conversion.total = $(value).val();
        }
    });

    to_send.date_from = $('#hidden_date_from_input').val();
    to_send.date_to = $('#hidden_date_to_input').val();
    to_send.number_of_returns = $('#hidden_number_of_returns_input').val();
    to_send.number_of_orders = $('#hidden_number_of_orders_input').val() - number_of_excluded_rows;
    to_send.invoice_id = $('#hidden_invoice_id_input').val();

    // console.log(to_send);
    // return false;

    // $('#hidden_input').val(JSON.stringify(to_send));
    // $('#hidden_form').submit();
    const url = '/dashboard/accounts/print_excel_plain';
    const options = {
        method: "POST",
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            "X-Requested-With" : "XMLHttpRequest"
        },
        body: JSON.stringify(to_send)
    };

    my_ajax(url, options).then(data => {
        // console.log(data);

        if (data.data.length > 0) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = data.data;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    });
});

async function my_ajax(url, options) {
    const response = await fetch(url, options);
    return response.json();
}

$('#export_to_excel_spec').on('click', function() {
    // e.preventDefault();

    // $(':disabled').each(function(e) {
    // 	$(this).removeAttr('disabled');
    // });

    // we do it like this because form generate gazilions variables and server is complainig

    let to_send = {};

    to_send.invoice = {};
    to_send.invoice.client_info = [];
    to_send.invoice.our_info = [];
    to_send.invoice.invoice_footer = [];
    to_send.invoice.invoice_date;
    to_send.invoice.invoice_number;

    to_send.pre_table = {};
    to_send.pre_table.header = [];

    to_send.orders = {};
    to_send.orders.header = [];

    to_send.total = {};
    to_send.total.name = [];
    to_send.total.value = [];

    to_send.conversion = {};
    to_send.conversion.currency;
    to_send.conversion.rate;
    to_send.conversion.total;

    to_send.date_from;
    to_send.date_to;
    to_send.number_of_returns;
    to_send.number_of_orders;
    to_send.invoice_id;

    let number_of_excluded_rows = 0;

    $.each($('.invoice_input'), function(key, value) {
        if ($(value).attr('name') == 'invoice[invoice_date]') {
            to_send.invoice.invoice_date = $(value).val();
        }

        if ($(value).attr('name') == 'invoice[invoice_number]') {
            to_send.invoice.invoice_number = $(value).val();
        }

        if ($(value).attr('name') == 'invoice[client_info][]') {
            to_send.invoice.client_info.push($(value).val());
        }

        if ($(value).attr('name') == 'invoice[our_info][]') {
            to_send.invoice.our_info.push($(value).val());
        }

        if ($(value).attr('name') == 'invoice[invoice_footer][]') {
            to_send.invoice.invoice_footer.push($(value).val());
        }
    });

    $.each($('.pre_table_input'), function(key, value) {
        if ($(value).attr('name') == 'pre_table[header][]') {
            to_send.pre_table.header.push($(value).val());
        }
    });
    $(to_send.pre_table.header).each(function(key, value) {
        to_send.pre_table[value] = [];
    });
    $.each($('.pre_table_input'), function(key, value) {
        $(to_send.pre_table.header).each(function(k, v) {
            if ($(value).attr('name') == 'pre_table[' + v + '][]') {
                to_send.pre_table[v].push($(value).val());
            }
        });
    });

    $.each($('.orders_input'), function(key, value) {
        if ($(value).attr('name') == 'orders[header][]') {
            to_send.orders.header.push($(value).val());
        }
    });
    $(to_send.orders.header).each(function(key, value) {
        to_send.orders[value] = [];
    });
    to_send.orders['ids'] = [];

    let tmp_date = '';
    $.each($('.orders_input'), function(key, value) {
        if ($('#orders_total_' + $(value).attr('data-row')).hasClass('not_included')) {
            number_of_excluded_rows += 1;
            return true;
        }

        $(to_send.orders.header).each(function(k, v) {
            if ($(value).attr('name') == 'orders[' + v + '][]') {
                let value_to_push = $(value).val();
                to_send.orders[v].push(value_to_push);
            }
        });
    });

    $.each($('.orders_row'), function(key, value) {
        let row_number = $(value).attr('data-row');
        let push_ids = $('#show_info_' + row_number).attr('data-auftrag_id');
        to_send.orders['ids'].push(push_ids);
    })

    number_of_excluded_rows = number_of_excluded_rows / to_send.orders.header.length;

    $.each($('.total_input'), function(key, value) {
        if ($(value).attr('name') == 'total[name][]') {
            to_send.total.name.push($(value).val());
        }

        if ($(value).attr('name') == 'total[value][]') {
            to_send.total.value.push($(value).val());
        }
    });

    $.each($('.conversion_input'), function(key, value) {
        if ($(value).attr('name') == 'conversion[currency]') {
            to_send.conversion.currency = $(value).val();
        }

        if ($(value).attr('name') == 'conversion[rate]') {
            to_send.conversion.rate = $(value).val();
        }

        if ($(value).attr('name') == 'conversion[total]') {
            to_send.conversion.total = $(value).val();
        }
    });

    to_send.date_from = $('#hidden_date_from_input').val();
    to_send.date_to = $('#hidden_date_to_input').val();
    to_send.number_of_returns = $('#hidden_number_of_returns_input').val();
    to_send.number_of_orders = $('#hidden_number_of_orders_input').val() - number_of_excluded_rows;
    to_send.invoice_id = $('#hidden_invoice_id_input').val();

    // console.log(to_send);
    // return false;

    // $('#hidden_input').val(JSON.stringify(to_send));
    // $('#hidden_form').submit();
    const url = '/dashboard/accounts/print_excel_spec';
    const options = {
        method: "POST",
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            "X-Requested-With" : "XMLHttpRequest"
        },
        body: JSON.stringify(to_send)
    };

    my_ajax(url, options).then(data => {
        // console.log(data);

        if (data.data.length > 0) {
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = data.data;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    });
});

//END REPORTS
