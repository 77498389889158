$(document).ready(function(){

    $("#download_articles_report").on('click', function(){
        var filterForm = document.getElementById('filter-forms');
        var filterFormData = new FormData(filterForm);
        
        var data = {};

        for (var pair of filterFormData.entries()) {
            if (pair[1]) {
                data[pair[0]] = pair[1];
            }
        }
        

        $.ajax({
            url: '/api/articles/all',
            method: 'POST',
            data: data,
            dataType: 'json',
            success:function(response, status, xhr){
                if(status === 'success' && xhr.readyState === 4){
                    // console.log(response);
                    var docName = response.filename;
                    var link = response.file;
                    var a = $("<a />");
                    a.attr("download", docName);
                    a.attr("href", link);
                    $("body").append(a);
                    a[0].click();
                    a.remove();
                    // console.log(a);
                }
            },
            error: function(xhr){
                if(xhr.status === 401){
                    window.location.reload(true);
                }else{
                    console.log(xhr.status+' '+xhr.statusText);
                }
            },
            beforeSend: function(xhr){
                var ajaxMsgBox = $("#ajaxMsgBox");
                ajaxMsgBox.show();
                ajaxMsgBox.find('.ajaxMsg').append('Generating file. Please wait...');
            },
            complete: function(){
                $("#ajaxMsgBox").hide();
            }
        });//end ajax
    });
});