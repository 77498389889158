let my_where_am_i_string = window.location.href;

// we close all collapsable
$('.collapse').each(function (key, value) {
	$(value).removeClass('show');
});

// for each link
$('.nav-link').each(function (key, value) {
	// we remove the active_link class
	$(value).removeClass('active_link');
	
	// we add active_link class for the correspondgin one
	if ($(value).attr('href') == my_where_am_i_string) {
		$(value).addClass('active_link');

		// we open collapsable parent if it has one
		$(value).closest('div.collapse').addClass('show');
	}
});