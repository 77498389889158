$(document).on("click", ".check_uncheck", function(e){
    let is_checked = $(this).is(':checked')
    $('.users__warehouses').each(function(key, value){
        $(value).prop('checked', is_checked);
    });
});

$(document).on("click", ".check_uncheck2", function(e){
    let is_checked = $(this).is(':checked')
    $('.users__projects').each(function(key, value){
        $(value).prop('checked', is_checked);
    });
});