import selectSearch from "./select_search_input_class";

window.addEventListener("DOMContentLoaded", function () {
    const bundleEditPage = document.querySelector(".bundle_edit_page");

    $(function () {
        if (bundleEditPage) {
            const submitBtnCopy = document.querySelector(".submit__btn-copy");
            const submitBtnPaste = document.querySelector(".submit__btn-paste");

            const allDropdown = document.querySelectorAll(".custom__options");

            allDropdown.forEach((dropdownmenu) => {
                selectSearch(dropdownmenu);
            });

            /// form logic
            const deleteBtn = document.querySelectorAll(
                ".delete__bundle-article"
            );
            let iputElement = document.querySelector(
                "#list_of_bundle_article_to_delete"
            );
            // made for visualy moving button to bottom of page because of form structure is not possible
            deleteBtn.forEach((btn) => {
                btn.addEventListener("click", (e) => {
                    deleteOrderArticle(e.target);
                });
            });

            let arrayOfItemsToDelete = [];

            function deleteOrderArticle(element) {
                let wholeElement = element.closest("tr");
                let selectedElementValue = wholeElement
                    .querySelector(".bundle-article__id")
                    .getAttribute("value");
                arrayOfItemsToDelete.push(selectedElementValue);
                wholeElement.remove();
                iputElement.value = JSON.stringify(arrayOfItemsToDelete);
            }

            submitBtnPaste.addEventListener("click", () => {
                try {
                    submitBtnCopy.click();
                } catch (error) {
                    alert('Add article first before save');
                }
            });
        }
    });
});
