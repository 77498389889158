const { get } = require("jquery");

// $(window).load(function() {
$(document).ready(function () {
    // console.log("pre_alerys");

    $("#pre_alert_button").on("click", function () {
        const release_id = $(this).attr("data-release_id");

        const from_server = async () => {
            const response = await fetch(
                "/api/office/prealerts/list?release_id=" + release_id, {
                    method: 'get',
                    headers: {
                        "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        "X-Requested-With" : "XMLHttpRequest"
                    }
                }
            );
            const data = await response.json();

            // console.log(data);
            
            
            return data;
        };

        from_server().then(data => {
            // console.log(data.separated_orders);
            
            document.getElementById('release_id_span').innerHTML = data.release_id;
            
            const table_body = document.getElementById('prealerts_modal_table_body');
            
            let courier_order_ids = {};

            let string = '';
            let counter = 1;

            // console.log(data.separated_orders);
            

            for (let key in data.separated_orders) {
                


                let value = data.separated_orders[key];
                let diff = value.order_orders_id.filter(x => !value.prealert_orders_id.includes(x));

                console.log(diff);
                
                
                
                courier_order_ids[value.courier_id] = diff;

                // console.log(JSON.stringify(courier_order_ids));
                
                
                string += '<tr>';
                    
                string += '<td class="text-center">';
                    string += counter
                string += '</td>';
                
                string += '<td class="text-center">';
                    string += key
                string += '</td>';
                // sliceIntoChunks(value.order_orders_id, 1);
                string += '<td class="text-center">';
                    string += JSON.stringify(value.order_orders_id);
                string += '</td>';
                
                string += '<td class="text-center">';
                    string += JSON.stringify(value.prealert_orders_id);
                string += '</td>';

                string += '<td class="text-center">';
                    string += JSON.stringify(diff);
                string += '</td>';
                
                string += '<td class="text-center">';
                    string += '<input';
                        string += ' form="get_prealerts_form"';
                        string += ' type="checkbox"';
                        string += ' name="courier_order_ids[]"';
                        string += ' class="courier_order_ids"';
                        string += ' value=\'';
                            string += JSON.stringify(courier_order_ids);
                        string += '\'';
                        string += diff.length === 0 ? '' : ' checked';
                    string += '>';
                string += '</td>';
                
                string += '<td class="text-center">';
                    string += '<input';
                        string += ' form="get_prealerts_form"';
                        string += ' type="checkbox"';
                        string += ' name="all_orders_courier[]"';
                        string += ' class="all_orders_courier"';
                        string += ' value="' + value.courier_id + '"';
                    string += '>';
                string += '</td>';

                string += '</tr>';

                counter += 1;
            }

           

            table_body.innerHTML = string;
            

            document.getElementById('hidden_release_id').value = data.release_id;
        });
    });
});