class LabelMaster {
  constructor(orders) {
    this.allOrders = orders;
	this.requestedOrders = [];
	this.requestedOrdersPortion = [];
	this.requestedOrdersWithErrors = [];
	this.orderType = false;
	this.orderLimit = 1000;
	this.errorLimitStop = 1000;
  }

  setOrderType(type) {
	this.orderType = type;
	this.collectOrders();
  }
  // collect orders copy pastes original order array and then removes elements if type has requested it so
  collectOrders(){
	this.requestedOrdersPortion = [];  
	this.requestedOrders = [];  
	//if we want labels for all operators we click all and copy paste array/
	// do not forget = willo not cpy paste it
	if( this.orderType == 'all') {
		//safe COPY PASTE array using Array.from is a neat function for that
		this.requestedOrders = Array.from(this.allOrders); 
		
	} else {
		// filter creates
		this.requestedOrders = this.allOrders.filter(order => order.courier_id == this.orderType);
	}
	
	//console.log('utc_unix_timestamp: ' + pm.variables.get('utc_unix_timestamp'));
	
	for(var count = 0; count < this.orderLimit; count++){
		this.requestedOrdersPortion.push(this.requestedOrders.shift()); // this one can return undefined
	}
	//requested orders portion can have undefined elements we need to remove them
	this.requestedOrdersPortion = this.requestedOrdersPortion.filter(order => order != undefined);
	
	this.drawTable();
	
  }
  
  
  drawTable(errors = false){
	$( "#label_obtainer_div" ).html('');
	var html = '';
	html += '<table class="table">';
	html += '<thead>';
	html += '<tr>';
	html += '<th scope="col">Reference</th>';
	html += '<th scope="col">Courier</th>';
	html += '<th scope="col" class = "text-end" id = "spiner">Scan number</th>';
	html += '</tr>';
	html += '</thead>';
	html += '<tbody id = "order_body_table">';		
	
	if(errors == false){
		this.requestedOrdersPortion.forEach(function (e){
			html += '<tr>';
				html += '<td>' + e.reference + '</td>';
				html += '<td>' + e.courierName + '</td>';
				if(e.label_obtained){
					// do nto worry about tracking and ua number, one will always be empty :)
					html += '<td class = "text-end text-success">' + e.tracking_number + e.ua_number + '</td>';
				} else {
					if(e.label_obtained_report == null) {e.label_obtained_report = '';}
					html += '<td class = "text-end" >'
						+ '<a class = "text-danger blue-link" target="_blank" href = "'+ e.editLink +'">' 
							+ e.label_obtained_report 
						+ '</a></td>';
				}
			html += '</tr>';
		});
	} else {
		this.requestedOrdersWithErrors.forEach(function (e){
			html += '<tr>';
				html += '<td>' + e.reference + '</td>';
				html += '<td>' + e.courierName + '</td>';
				if(e.label_obtained_report == null) {e.label_obtained_report = '';}
				html += '<td class = "text-end" >'
					+ '<a class = "text-danger blue-link" target="_blank" href = "'+ e.editLink +'">' 
						+ e.label_obtained_report 
					+ '</a></td>';
			html += '</tr>';
			
		});
	}
	
	
	html += '</tbody>';
	html += '</table>';			
	$( "#label_obtainer_div" ).html(html);
  }
  
  /*
  * To call recursive function in order aquire labels one by one only after the last ajax is done with request
  * we do this so that there is no havy load on the server by requesting more then 100 labels at one point
  * here is the scenario. Imagine you send 100 dpd labels in less then several seconds. DPD will not respond
  * we need to wait for the last label to finish in order to get next label.
  */
  callLabelObtainer(){
	var result = this.fetchLabel(0); 
  }
  
  
  fetchLabel(counter){
	//if there are no extracted orders to be obtained
	// console.log(this.requestedOrdersPortion.length);
	if(this.requestedOrdersPortion.length == 0){
		
		// console.log(this.requestedOrdersWithErrors);
		
		if(this.requestedOrdersWithErrors.length > 0 ){
			fillAlertBox('danger', 'Procedure is done. '
				+ this.requestedOrdersWithErrors.length
				+ ' orders failed to obtain the label');
			this.drawTable(true);
		} else {
			fillAlertBox('success', 'All labels for this request are obtained');
		}
		return false;
	}
	
	if(counter == this.requestedOrdersPortion.length){
		this.rebuildRequestedOrdersPortion();
		return counter;
	} else {
		
		var labelOptions = {
			'POST_HU'				:serializeArray(document.querySelector('#FORM_POST_HU')),
			'POST_HU_TRACKED'		:serializeArray(document.querySelector('#FORM_POST_HU_TRACKED')),
			'COURIER_DPD'			:serializeArray(document.querySelector('#FORM_COURIER_DPD')),
			'ASENDIA_CH'			:serializeArray(document.querySelector('#FORM_ASENDIA_CH')),
			'COURIER_DHL'			:serializeArray(document.querySelector('#FORM_COURIER_DHL')),
			'COURIER_DHL_EXPRESS'	:serializeArray(document.querySelector('#FORM_COURIER_DHL_EXPRESS')),
			'COURIER_DHL_DE'		:serializeArray(document.querySelector('#FORM_COURIER_DHL_DE')),
			'POST_CZ'				:serializeArray(document.querySelector('#FORM_POST_CZ')),
		}
		
		let data = {order:this.requestedOrdersPortion[counter], options:labelOptions , release:window.release_id};
		var that = this;
		
		$.ajax({
			url: "/api/labels/get", 
			method:"POST",
			data:data,
			success: function(result){
				that.writeResult(result, counter);
				// console.log(result);
			},
			complete: function(result){
				counter ++;
				counter = that.fetchLabel(counter);
				that.drawTable();
			},	
		});
		return counter;
	}	
  }
   
  writeResult(result, counter){
	this.requestedOrdersPortion[counter].label_obtained 		= result.label_obtained;
	this.requestedOrdersPortion[counter].label_obtained_report 	= result.label_obtained_report;
	this.requestedOrdersPortion[counter].tracking_number 		= result.tracking_number;
	this.requestedOrdersPortion[counter].ua_number 				= result.ua_number;
  }
  
  rebuildRequestedOrdersPortion(){
	var that = this;
	setTimeout(function (){that.validateRequestedOrdersPortionAndRebuildIfAllowed()}, 1000);
  }
  
  validateRequestedOrdersPortionAndRebuildIfAllowed(){
	let that = this;
	this.requestedOrdersPortion.forEach(function(e){
		if(e.label_obtained == false) {
			that.requestedOrdersWithErrors.push(e);
			console.log(that.requestedOrdersWithErrors);
		}
	});
	
	if(that.requestedOrdersWithErrors.length > 0){
		// errors are found, some order did not receive error. 
		// it has to be manualy fixed on a compleatly diferent page
		fillAlertBox('danger', 'Errors are present! Procedure is still ongoing please wait');

		// If there are to many errors please do not continue
		if(that.requestedOrdersWithErrors.length >= this.errorLimitStop){
			//console.log(that.requestedOrdersWithErrors.length);
			fillAlertBox('danger', 'To many erros, procedure halted. Please review oreders with errors');
			this.drawTable(true);
			return false;
		}
		
	}
	
	// now since there are no errors
	// we remove those orders that were already sent 
	// and start all over
	let newOrders =  this.allOrders.filter(
		order => this.requestedOrdersPortion.find(el => el.id == order.id) == undefined
	);
	this.allOrders = newOrders;
	this.collectOrders(); //now table is also updated and new portion array has been finished
	
	setTimeout(function (){that.callLabelObtainer()}, 1000);

  }
}

const lbl = new LabelMaster(window.release_orders);



$("#obtainType" ).change(function (e) {
	lbl.setOrderType($(this).val());
});



/*
*Button press. Validate some settings before procedure starts
*
*/
/*
	// if hte procedure is not jet started
	if(this.procedureStarted == false){
		// get the value of dropdown
		selectVal = $('#obtainType :selected').val();
		// see if it is selected // meaning false selection
		if(selectVal == 'selected'){
			alert('Please select an option from dropdown manu');
			return false;
		}
		// if we have some option
		else {
			// first start by telling user we have started the procedure
			fillAlertBox('primary', 'Please wait for the procedure to finish');
			//change button to be rebuild button now
			$('#getLabelButton').removeClass("btn-success");	
			$('#getLabelButton').addClass("btn-warning");
			$('#getLabelButton').text("rebuild");
			// mark procedure as started
			this.procedureStarted = true;
			//begin label procedures
			var result = this.fetchLabel(0); // we start with zero pozition 
		}
		
	} else {
		location.reload();
	}
	*/

// to control button behavior
var labelProcedureStarted = false;

// when you click on get labels this happens
$( "#getLabelButton" ).click(function() {
	
	// prevend double click
	$("#getLabelButton" ).prop('disabled', true);
	
	setTimeout(function() {
		$("#getLabelButton" ).prop('disabled', false);
	}, 3000);

	// if procedure is not started yet, set all up for label procedures to start
	if(labelProcedureStarted == false){
		
		// get the value of dropdown
		selectVal = $('#obtainType :selected').val();
		
		// see if it is select // meaning false selection no bvalid option is seleced
		if(selectVal == 'select'){
			alert('Please select an option from dropdown menu');	
		}
		// if we have some option
		else {
			// first start by telling user we have started the procedure
			fillAlertBox('primary', 'Please wait for the procedure to finish');
			//change button to be rebuild button now
			$('#getLabelButton').removeClass("btn-success");	
			$('#getLabelButton').addClass("btn-warning");
			$('#getLabelButton').text("rebuild");
			// mark procedure as started
			labelProcedureStarted = true;
			//begin label procedures
			lbl.callLabelObtainer(); // we start with zero pozition 
		}
		
	} else {
		// HAHA YES THIS IS FAMEOUS RELOAD :D:D:D 
		// such a wonderfull and complex function that will rebuild our arrays.
		// ok so reselect array select only the ones that needed to be selected
		// and all of that with master fucntion relad page HAHAHAHAHA :D
		location.reload();
	}
	
});





var serializeArray = function (form) {

	// Setup our serialized data
	var serialized = [];
	// Loop through each field in the form
	for (var i = 0; i < form.elements.length; i++) {

		var field = form.elements[i];

		// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
		if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

		// If a multi-select, get all selections
		if (field.type === 'select-multiple') {
			for (var n = 0; n < field.options.length; n++) {
				if (!field.options[n].selected) continue;
				
				serialized.push({
					name: field.name,
					value: field.options[n].value
				});	
			}
		}

		// Convert field data to a query string
		else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
			
			serialized.push({
				name: field.name,
				value: field.value
			});
			
		}
	}
	
	return serialized;
};

function fillAlertBox(type, text){
	bhtml = '';
	bhtml += '<div class="col-md-12 p-2 alert-'+type+'" role="alert">';
		bhtml += '<span id = "alert_holder_text">'+text+'</span>';
	bhtml += '</div>'
	$( "#alert_box" ).html(bhtml);
}