// const { forEach } = require("lodash");
console.log('file functions.js included');
let backToTopBtn = document.getElementById("btn-back-to-top");
window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      if(backToTopBtn){
        backToTopBtn.style.display = "block";
      }
    } else {
      if(backToTopBtn){
        backToTopBtn.style.display = "none";
      }
    }
  }
if(backToTopBtn){
  backToTopBtn.addEventListener("click", backToTop);
}
function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}


const siblingButtons = document.querySelectorAll('.sibling-open__close-list');
const siblingButtons_zwei = document.querySelectorAll('.sibling-open__close-list2');

siblingButtons.forEach(button => {
  button.addEventListener('click',()=>{
    button.nextElementSibling.classList.toggle('list__expand--active');
  })
});
siblingButtons_zwei.forEach(button => {
  button.addEventListener('click',()=>{
    button.nextElementSibling.classList.toggle('list2__expand--active');
  })
});

const wrappedButtons = document.querySelectorAll('.wrapped-open__close-list');
const wrappedButtons_zwei = document.querySelectorAll('.wrapped-open__close-list2');

wrappedButtons.forEach(button => {
  button.addEventListener('click',()=>{
    button.parentNode.nextElementSibling.classList.toggle('list__expand--active');
  })
});
wrappedButtons_zwei.forEach(button => {
  button.addEventListener('click',()=>{
    button.parentNode.nextElementSibling.classList.toggle('list2__expand--active');
  })
});
