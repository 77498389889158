window.addEventListener('load', function () {
    $(function(){
        if(document.querySelector(".search-project")){
            const selected = document.querySelector(".selected");
            const optionsContainer = document.querySelector(".options-container");
            const searchBox = document.querySelector(".search-box input");

            const optionsList = document.querySelectorAll(".option");

            selected.addEventListener("click", () => {
              optionsContainer.classList.toggle("active");

              searchBox.value = "";
              filterList("");


              if (optionsContainer.classList.contains("active")) {
                searchBox.focus();
              }
            });

            optionsList.forEach(o => {
              o.addEventListener("click", () => {
				if (o.firstChild.checked==true) {
					o.firstChild.checked=false;
				} else {
					o.firstChild.checked=true;
				}
              });
            });

            searchBox.addEventListener("keyup", function(e) {
              filterList(e.target.value);
            });

            const filterList = searchTerm => {
              searchTerm = searchTerm.toLowerCase();
              optionsList.forEach(option => {
                let label = option.firstElementChild.innerText.toLowerCase();
                if (label.indexOf(searchTerm) != -1) {
                  option.style.display = "block";
                } else {
                  option.style.display = "none";
                }
              });
            };



        }
    });
});