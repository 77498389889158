$(document).ready(function () {

    if (document.querySelector('.overbox__only')) {

        setInterval(function () {
            if (document.getElementById('overbox_tracking')) {
                if (document.getElementById('overbox_name') !== document.activeElement) {
                    if (document.getElementById('scan_number') !== document.activeElement) {
                        if (document.getElementById('overbox_tracking') !== document.activeElement) {
                            document.getElementById('overbox_tracking').focus();
                        }
                    }
                }
            }
        }, 200);

        function transformText(input) {
            let output = '';
            for (let i = 0; i < input.length; i++) {

                if (input[i] == "`") { output += ""; }
                else if (input[i] == "%") { output += ""; }
                else if (input[i] == "?") { output += "_"; }
                else if (input[i] == "Y") { output += "Z"; }
                else if (input[i] == "Z") { output += "Y"; }
                else { output += input[i]; }
            }

            return output;
        }
        // event listener on all tracking numbers to be able to copy
        function copyText() {
            let allScanNumber = document.querySelectorAll('.tracking__number');
            allScanNumber.forEach(number => {
                number.addEventListener('click', () => {
                    number.style.color='green';
                    let textarea = document.createElement("textarea");
                    textarea.textContent = number.innerText;
                    document.body.appendChild(textarea);
                    textarea.select();
                    setTimeout(() => {
                        number.style.color='black';
                    }, 2000);
                    try {
                        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
                    }
                    catch (ex) {
                        console.warn("Copy to clipboard failed.", ex);
                        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
                    }
                    finally {
                        document.body.removeChild(textarea);
                    }
                });
            })
        }
        copyText();

        let scan_number_value = document.getElementById('scan_number').value;
        let scan_overbox_data = document.getElementById('overbox_tracking').value;

        $('#overbox__form').on('submit', function (e) {

            let scan_number = document.getElementById('scan_number');
            let overbox_data = document.getElementById('overbox_tracking').value;
           
            if (overbox_data !== scan_overbox_data) {
                e.preventDefault();
            } else if (scan_number_value !== scan_number.value) {
                e.preventDefault();
                scan_number.value = transformText(scan_number.value);
                e.target.submit();
            }
            else {
                return
            }

            $('#overbox_table_body').html('');

            let finale = '';

            finale = transformText(overbox_data);

            let data = {
                overbox_tracking: finale,
                overbox_id: document.getElementById('overbox_id').value
            };

            console.log(overbox_data);

            if (data['overbox_tracking']) {               
                $.ajax({
                    url: "/dashboard/overbox/overbox-content",
                    method: "POST",
                    data: data,
                    success: function (response) {
                        console.log(response);
                        let html = '';
        
                        $.each(response, function (i, item) {
                            html += 
                                `<tr>
                                    <td> ${ response[i].overbox_id }  </td>
                                    <td><i class=" bi bi-clipboard me-2"></i>
                                        <span role="button" class="tracking__number" title="click to copy tracking number">
                                            ${ response[i].tracking_number }  
                                        </span>
                                    </td>
                                    <td>
                                        ${ response[i].status ? response[i].status : '' } 
                                    </td>                                            
                                    <td>
                                        ${ response[i].name ? response[i].name : '' } 
                                    </td>
                                    <td>
                                        <button class="btn btn-danger delete" data-overbox_content_id=" ${ response[i].id } " style="margin-left:10px;">
                                            Delete
                                        </button>
                                    </td>
                                </tr>`;
                        });
                        $('#overbox_table_body').prepend(html);
                        $("#overbox_tracking").val('');
                    },
                    complete: function () {
                            copyText();

                    },
                });
            }
        });

        $(document).on("click", ".delete", function () {

            let $ele = $(this).parent().parent();
            let id = $(this).data('overbox_content_id');
            $ele.css("background-color", "red");
            $ele.fadeOut();
            setTimeout(() => {
                $.ajax({
                    url: "/dashboard/overbox/delete/" + id,
                    type: "DELETE",
                    cache: false,
                    success: function (dataResult) {

                        let dataResults = JSON.parse(dataResult);
                        if (dataResults.statusCode == 200) {
                            $ele.fadeOut().remove();
                        }
                        copyText();
                    }
                });
            }, 1000);
        });
    }

}
)


    let createOverbox_form = document.querySelectorAll('#create_overbox_form input');

    createOverbox_form.forEach(input => {
        input.addEventListener("keyup", function(event){
            console.log(event.keyCode);
            // if(event.keyCode === 9){ //tab keyCode
            if(event.keyCode === 13){
                document.getElementById("overbox_date").focus();
            }  
        });
    });

