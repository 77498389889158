    window.selectAllOrdersIds = function (element) {

        let ordersIds = JSON.parse(localStorage.getItem('ordersIds'));

        ordersIds = [];
        if (element.checked) {
            ordersIds = firstOrdersIds;
        }

        document.querySelector('#ordersIds').value = JSON.stringify(ordersIds);
        document.querySelector('#orderCounter').innerHTML = ordersIds.length;
        document.querySelectorAll('input[id*="order-"]').forEach(function(el) {
            el.checked = element.checked;
        });

        localStorage.setItem('ordersIds', JSON.stringify(ordersIds));
    }

    window.modifyOrdersIds = function (orderId) {

        let ordersIds = JSON.parse(localStorage.getItem('ordersIds'));

        orderId = parseInt(orderId);

        if (ordersIds.includes(orderId))
            ordersIds = ordersIds.filter(e => e !== orderId);
        else
            ordersIds.push(orderId);

        ordersCount = ordersIds.length;
        if (ordersCount > 0)
            document.querySelector('#orderCounterCheckBox').checked = true;

        if (ordersCount > 30) {
            ordersIds = ordersIds.filter(e => e !== orderId);
            alert('You cannot select more than 30 orders');
            return false;
        }

        document.querySelector('#ordersIds').value = JSON.stringify(ordersIds);
        document.querySelector('#orderCounter').innerHTML = ordersIds.length;

        localStorage.setItem('ordersIds', JSON.stringify(ordersIds));
    }

    let ordersIds = JSON.parse(localStorage.getItem('ordersIds'));
    if (!ordersIds) {
        localStorage.setItem('ordersIds', JSON.stringify([]));
    }

    if (document.querySelector('#orderCounter')) {

        document.querySelector('#orderCounter').innerHTML = ordersIds.length;
        if (ordersIds.length > 0)
            document.querySelector('#orderCounterCheckBox').checked = true;

        document.querySelector('#ordersIds').value = JSON.stringify(ordersIds);
        document.querySelectorAll('input[id*="order-"]').forEach(function(el) {
            if (ordersIds.includes(parseInt(el.dataset.id)))
                el.checked = true;
        });
    }

    localStorage.setItem('ordersIds', JSON.stringify(ordersIds));
