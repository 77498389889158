$(document).ready(function(){
    if (document.body.contains(document.getElementById('receiver_same_as_buyer'))) {
        $('#receiver_same_as_buyer').on('click', function(event){
            if ($('#receiver_same_as_buyer').is(':checked')) {
                var buyer_full_name = $('#client_upload_form_billing_full_name').val();
                var buyer_first_name = $('#client_upload_form_billing_first_name').val();
                var buyer_last_name = $('#client_upload_form_billing_last_name').val();
                var buyer_company = $('#client_upload_form_billing_company').val();
                var buyer_address_1 = $('#client_upload_form_billing_address_1').val();
                var buyer_address_2 = $('#client_upload_form_billing_address_2').val();
                var buyer_address_3 = $('#client_upload_form_billing_address_3').val();
                var buyer_city = $('#client_upload_form_billing_city').val();
                var buyer_zip = $('#client_upload_form_billing_zip').val();
                var buyer_country_code = $('#client_upload_form_billing_country_code').val();
                var buyer_province = $('#client_upload_form_billing_province').val();
                var buyer_phone = $('#client_upload_form_billing_phone').val();
                var buyer_email = $('#client_upload_form_billing_email').val();

                $('#client_upload_form_shipping_full_name').val(buyer_full_name);
                $('#client_upload_form_shipping_first_name').val(buyer_first_name);
                $('#client_upload_form_shipping_last_name').val(buyer_last_name);
                $('#client_upload_form_shipping_company').val(buyer_company);
                $('#client_upload_form_shipping_address_1').val(buyer_address_1);
                $('#client_upload_form_shipping_address_2').val(buyer_address_2);
                $('#client_upload_form_shipping_address_3').val(buyer_address_3);
                $('#client_upload_form_shipping_city').val(buyer_city);
                $('#client_upload_form_shipping_zip').val(buyer_zip);
                $('#client_upload_form_shipping_country_code').val(buyer_country_code);
                $('#client_upload_form_shipping_province').val(buyer_province);
                $('#client_upload_form_shipping_phone').val(buyer_phone);
                $('#client_upload_form_shipping_email').val(buyer_email);
            } else {
                $('#client_upload_form_shipping_full_name').val('');
                $('#client_upload_form_shipping_first_name').val('');
                $('#client_upload_form_shipping_last_name').val('');
                $('#client_upload_form_shipping_company').val('');
                $('#client_upload_form_shipping_address_1').val('');
                $('#client_upload_form_shipping_address_2').val('');
                $('#client_upload_form_shipping_address_3').val('');
                $('#client_upload_form_shipping_city').val('');
                $('#client_upload_form_shipping_zip').val('');
                $('#client_upload_form_shipping_country_code').val('');
                $('#client_upload_form_shipping_province').val('');
                $('#client_upload_form_shipping_phone').val('');
                $('#client_upload_form_shipping_email').val('');
            }
        });
    }
});

