$(document).ready(function(){
    $('#ajaxMsgBox').on('click', function(e) {
        $('#ajaxMsgBox').hide();
    });

    $('#add_new_sku_btn').on('click', function(e) {
        $('.div_to_clone').first().clone().appendTo('#div_to_clone_into');
        $('.new_skus_select').last().val('');
        $('.new_skus_quantities').last().val('');
        $('.new_skus_prices').last().val('');
    });

    $('#submit_form_btn').on('click', function(e) {
        e.preventDefault();

        // check if all is OK
        // no rule name
        if ($('#rule_name').val() == '') {
            show_my_message('Rule must have a name.');
            return false;
        }

        // no rule type
        if (!$('#rule_type_alter_sku').is(':checked') && !$('#rule_type_alter_dc').is(':checked')) {
            show_my_message('Check one of rule types.');
            return false;
        }

        // no ifs
        let check_weight_radio = false;
        $.each($('.weight_radio'), function(key, value) {
            if ($(value).is(':checked')) {
                check_weight_radio = true;
                return false;
            }
        });

        let check_select_new_sku = false;
        $.each($('.new_skus_select'), function(key, value) {
            if ($(value).val != '') {
                check_select_new_sku = true;
                return false;
            }
        });
        if (($('#old_sku').val() == '' && !check_select_new_sku) && $('#country_whitelist').val() == '' && $('#country_blacklist').val() == '' && !check_weight_radio && $('#weight_value').val() == '') {
            show_my_message('Set condition(s).');
            return false;
        }

        // no thens
        let check_new_sku_array = false;
        $.each($('.new_skus_select'), function(key, value) {
            let new_sku_quantity_element = $('.new_skus_quantity')[key];
            if ($(value).val() != '' && $(new_sku_quantity_element).val() != '') {
                check_new_sku_array = true;
                return false;
            }
        });
        if (!check_new_sku_array && $('#new_dc').val() == '') {
            show_my_message('Set result(s).');
            return false;
        }

        // now something is set
        // lets check if then correspond with if
        /*
            everything i come up has too many ifs, do think about this
        */

        // show_my_message('Submiting the form');
        // return false;

        $('#do_the_alter_order_form').submit();
    });
});

function show_my_message(msg) {
    $('#ajaxMsgBox').hide();
    $('#ajaxMsgBox').find('.ajaxMsg').html('');
    $('#ajaxMsgBox').show();
    $('#ajaxMsgBox').find('.ajaxMsg').append(msg);
    window.scrollTo({ top: 0, behavior: 'smooth' });
}