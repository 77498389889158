window.addEventListener('load', function () {
    $(function(){
        if(document.querySelector(".reship-order__page")){

            const rshipSubmitBtn = document.querySelector('.reship__button');

            const rshipSubmitBtnClone = document.querySelector('.reship__button--clone');

            rshipSubmitBtnClone.addEventListener('click', ()=> rshipSubmitBtn.click());
        }
    });
});
