// we first need to make our app focus constantly on the input field
window.setInterval(function() {
	$( "#scan" ).focus();
}, 2000);

var stopSubmiting = 0;

$('#scan').on('input',function(e) {
	if(stopSubmiting == 0){
		stopSubmiting = 1;
		setTimeout(function() {	
			var remebered = document.getElementById('scan').value;
			var finale = '';

			for(var i = 0; i < remebered.length; i++){
				
				if(remebered[i] == "`")		{finale+= "";}
				else if(remebered[i] == "°"){finale+= "";}
				else if(remebered[i] == "^"){finale+= "";}
				else if(remebered[i] == "?"){finale+= "_";}
				else if(remebered[i] == "Y"){finale+= "Z";}
				else if(remebered[i] == "Z"){finale+= "Y";}
				else {finale += remebered[i];}
			}
			
			remebered = finale;

			document.getElementById('scan').value = remebered;
			submitajax(remebered);
			stopSubmiting = 0;
		}, 200);
	}
});

if (document.body.contains(document.getElementById('dwn_scanned_label_anyway'))) {
	document.getElementById('dwn_scanned_label_anyway').addEventListener('click', function() {
		do_the_print();
	})
}

if (document.body.contains(document.getElementById('my_manual_scan_wrap_div'))) {
	for (el of document.getElementsByClassName('my_manual_scan_btn')) {
		el.addEventListener('click', function(e) {
			let data = {id:e.target.getAttribute('data-order_id')}
			$.ajax({
				url: "/api/slapp/order/get_manual",
				data:data,
				success: function(result) {
					if (typeof result.error !== 'undefined') {
						alert(result.error);
					} else {
						if (typeof result.file_path !== 'undefined') {
							document.getElementById('manual_scan_iframe').src = result.file_path;
							$('iframe#manual_scan_iframe').on('load', function() {
								window.frames["manual_scan_iframe"].focus();
								window.frames["manual_scan_iframe"].print();
							});

							for (el of document.getElementsByClassName('my_manual_scan_btn')) {
								if (e.target.getAttribute('data-order_id') == result.order_id) {
									e.target.remove();
								}
							}
						}
					}
				}
			});
		})
	}
}

if (document.body.contains(document.getElementById('my_manual_details_scan_wrap_div'))) {
	for (el of document.getElementsByClassName('my_manual_details_scan_btn')) {
		el.addEventListener('click', function(e) {
			let data = {id:e.target.getAttribute('data-order_id')}
			$.ajax({
				url: "/api/slapp/order/get_manual",
				data:data,
				success: function(result) {
					if (typeof result.error !== 'undefined') {
						alert(result.error);
					} else {
						if (typeof result.file_path !== 'undefined') {
							document.getElementById('manual_details_scan_iframe').src = result.file_path;
							$('iframe#manual_details_scan_iframe').on('load', function() {
								window.frames["manual_details_scan_iframe"].focus();
								window.frames["manual_details_scan_iframe"].print();
							});

							for (el of document.getElementsByClassName('my_manual_details_scan_btn')) {
								if (e.target.getAttribute('data-order_id') == result.order_id) {
									e.target.remove();
								}
							}
						}
					}
				}
			});
		})
	}
}

function submitajax(orderId){
	let data = {id:orderId};

	$.ajax({
		url: "/api/slapp/order/get",
		data:data,
		success: function(result){
			let should_i_print = fillUpSlappPageWithOrderResult(result);

			if (should_i_print) {
				$('iframe#iframe').on('load', function(){
					window.frames["iframe"].focus();
					window.frames["iframe"].print();
				});

				// $('iframe#iframe').on('load', do_the_print);
				// $('iframe#iframe').off('load', do_the_print);
			}

			document.getElementById('acctual_element_for_number_of_my_not_scanned_orders_all').innerHTML = result.number_of_not_scanned_all;
			document.getElementById('acctual_element_for_number_of_my_not_scanned_orders_today').innerHTML = result.number_of_not_scanned_today;
		}
	});
}

function do_the_print() {
	window.frames["iframe"].focus();
	window.frames["iframe"].print();
}

function fillUpSlappPageWithOrderResult(result){
	document.getElementById('scan').value = '';
	document.getElementById('slot_danger_message_wrapper').className = "row col-md-12 p-2 d-none";

	document.getElementById('dwn_scanned_label_anyway').style.display = 'none';

	if(result.error){
		document.getElementById('slot_danger_message_wrapper').className = "row col-md-12 p-2";
		document.getElementById('slot_danger_message').innerHTML = result.error;
		document.getElementById('iframe').src = "/slapp.pdf";
		emptyFields();

		return false;
	} 
	else {
		if(parseInt(result.scanned_occurrence) > 1){
			$('iframe#iframe').off();
			
			document.getElementById('slot_danger_message_wrapper').className = "row col-md-12 p-2";
			// document.getElementById('slot_danger_message').innerHTML = 'Order scanned more then once ! '
			// + ' Number of occurences is ' + result.scanned_occurrence;
			document.getElementById('slot_danger_message').innerHTML = 'This order is already scanned!';

			document.getElementById('dwn_scanned_label_anyway').style.display = 'block';
			// document.getElementById('dwn_scanned_label_anyway').setAttribute('data-labelUrl', result.labelUrl);
		} 

		document.getElementById('slot_release_id').innerHTML = result.release_id;
		document.getElementById('slot_total_orders').innerHTML = result.release_total_orders;
		document.getElementById('slot_scanned_orders').innerHTML = result.release_scanned_orders;
		document.getElementById('slot_pending_orders').innerHTML = result.release_remaining_orders;
		
		document.getElementById('slot_courier').innerHTML = result.courier;
		document.getElementById('slot_to').innerHTML = "<br />" +
		result.order.shipping_full_name + "<br />" +
		result.order.shipping_country_code + " " +
		result.order.shipping_city;

		let skuHolder = document.getElementById('slot_sku_holder');
		
		skuHolder.innerHTML = "";
		
		result.order_products.forEach( element => {
			skuHolder.innerHTML += 
			'<div class="col-md-3 m-3">'
				+'<h6>' + element.article.sku + " x " + element.quantity +'</h6>'
				+'<img class="shadow-sm border" height="150" width="150" '
				+'alt = "image not found" src = "/images/sku/' 
				+ element.article.sku + '.jpg">'
			+'</div>';
		});

		//document.getElementById('iframe').src = result.label;
		document.getElementById('iframe').src = result.labelUrl;

		if(parseInt(result.scanned_occurrence) < 2){
			return true;
		}

		return false;
	}	
}

function emptyFields(result){
	document.getElementById('slot_release_id').innerHTML = "";
	document.getElementById('slot_total_orders').innerHTML = "";
	document.getElementById('slot_scanned_orders').innerHTML = "";
	document.getElementById('slot_pending_orders').innerHTML = "";
	document.getElementById('slot_courier').innerHTML = "";
	document.getElementById('slot_to').innerHTML = "";
	document.getElementById('slot_sku_holder').innerHTML = "";
}