$(".check_all").on("click", function(){
    let is_checked = $(this).is(':checked')
    $(".check_one").each(function(key, value){
        $(value).prop('checked', is_checked);
    });
});


$(document).ready(function () {
    // console.log("pre_alerys");

    $(".prealert_status").on("click", function (e) {
        e.preventDefault();
        loading_spinner();

        $('#prealerts_table_body').html('');

        const transaction_id = $(this).attr("data-transaction_id"); 

        $.ajax({
            url: "/dashboard/release/prealerts/status/" + transaction_id,
            type: "get"
        })
        .done(function( data ) {
        // loading_spinner();
        remove_spinner();
        console.log(JSON.parse(data));
        let json_data = JSON.parse(data);
        // console.log(json_data[0].message);
        // console.log(json_data.ResultSendParcelsList);
        

        let string = '';
        if (json_data.ResultSendParcelsList != undefined ) {
            let counter = 1;
            let check_error = false;
            // console.log(result.length);
            $('.error-msg').empty();
            remove_spinner();
            $.each(json_data.ResultSendParcelsList, function (i, item) {
                let value = '';
                $.each(json_data.ResultSendParcelsList[i].parcelStateResponse, function (k, val) {
    
                    value += val.responseText + " | ";
                });
    
    
                let value2 = '';
                $.each(json_data.StatusResponseList, function (k, val) {
    
                    value2 += val.responseText + " | ";
                });
                // let check_if_ok = "table-success";
                // value = "OK | ";
                if (value != "OK | " && !value.startsWith("INFO", 0)) {
                    // check_if_ok = "table-danger";

                    string += `<tr class="table-danger">
                                <td>
                                    ${item.recordNumber}
                                </td>
                                <td>
                                    ${value.substring(0,value.length - 3)}
                                </td>
                                <td>
                                    ${value2.substring(0, value2.length - 3)}
                                </td>
                            </tr>`;

                    check_error = true;
                }

                
            });
            if (check_error) {
                $('#prealerts_table_body').append(string);
            } else {
                let string1 = `<div class="alert alert-success" role="alert">
                ${transaction_id} All orders are OK!
              </div>`;
                $('#success_msg').empty();
                $('#success_msg').append(string1);
                
            }
        } else {
            
            if (json_data[0].message != undefined) {
                string = `
                    <div class="alert alert-danger h-100 d-flex align-items-center justify-content-center"  role="alert">
                        ${json_data[0].message}   
                    </div>
                    `;
            }
            

            $('.error-msg').empty();
            $('.error-msg').append(string);       
        }
        
        
        
        }).always(function(){
            remove_spinner();
        });
        
        
    });
});

function remove_spinner(){
    $("#loading-spinner").remove();

}


function loading_spinner(){
	let loader = document.createElement('div');
	let spinner = document.createElement('img');

	loader.setAttribute("id", "loading-spinner");
	spinner.setAttribute("src", "/images/spinner.gif");
	spinner.style.position = 'absolute';
	spinner.style.width = '150px';
	spinner.style.height = '150px';
	spinner.style.transform = 'translateX(50%)';
	spinner.style.transform = 'translateY(-50%)';
	spinner.style.left = '50%';
	spinner.style.top = '50%';
	
	document.body.appendChild(loader);
	loader.appendChild(spinner);
}



