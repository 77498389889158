$(document).ready(function(){
    

    

    

	$("#download_returns_report").on('click', function(){
        //var forma = $(this).closest('form');
        // var formData = $(this).closest('forma').serializeArray().reduce(function(obj, item) {
        //     obj[item.name] = item.value;
        //     return obj;
        // }, {});
        var filterForm = document.getElementById('filter-form');
        var filterFormData = new FormData(filterForm);
        
        var data = {};

        // console.log(filterFormData);
        // console.log('generate return report');

        for (var pair of filterFormData.entries()) {
            if (pair[1]) {
                data[pair[0]] = pair[1];
            }
        }

        $.ajax({
            url: '/api/office/documents/return',
            method: 'POST',
            //async: false,
            data: data,
            dataType: 'json',
            success:function(response, status, xhr){
                if(status === 'success' && xhr.readyState === 4){
                    // console.log(response);
                    var docName = response.filename;
                    var link = response.file;
                    var a = $("<a />");
                    a.attr("download", docName);
                    a.attr("href", link);
                    $("body").append(a);
                    a[0].click();
                    a.remove();
                }
            },
            error: function(xhr){
                if(xhr.status === 401){
                    window.location.reload(true);
                }else{
                    console.log(xhr.status+' '+xhr.statusText);

                }
            },
            beforeSend: function(xhr){
                var ajaxMsgBox = $("#ajaxMsgBox");
                ajaxMsgBox.show();
                ajaxMsgBox.find('.ajaxMsg').append('Generating file. Please wait...');
            },
            complete: function(){
                $("#ajaxMsgBox").hide();
            }
        });//end ajax

    });

	$("#adding_return_items").on('click', 'button.add_return_item', function(){

		var orderItemRow = $(this).closest('tr');
        //var orderItemId = orderItemRow.find("input[name='order_item_id']").val();
		var orderItemId = orderItemRow.find("input.order_item_id").val();

        returnItemsMoving.loadOrderItemData(orderItemId);
        returnItemsMoving.displayRowToItemsReturnTable(returnItemsMoving.orderItem);

        // returnItemsMoving.removeEventsForQuantityNoUsable();
        returnItemsMoving.removeHandler();
        returnItemsMoving.createEventsForQuantityNoUsable();

        orderItemRow.remove();
	});

    $("#removing_return_items").on('click', 'button.remove_return_item', function(){

        //console.log('remove item');

        var orderItemRow = $(this).closest('tr');
        var orderItemId = orderItemRow.find("input.order_item_id").val();

        //console.log(orderItemId);

        returnItemsMoving.loadOrderItemData(orderItemId);
        //console.log(returnItemsMoving.orderItem)
        
        returnItemsMoving.displayRowToOrderItemsTable(returnItemsMoving.orderItem);
        orderItemRow.remove();
    });


    var returnItemsMoving = (function returnItemsModule(){

        var orderItems = [];
        var itemsToReturn = [];
        var orderItem = '';

        var addOrderItem = function(item){
            orderItems.push(item);
        }

        var removeOrderItem = function(item){
            var arrIndex = orderItems.indexOf(item);
            if (arrIndex >= 0) {
                orderItems.splice(arrIndex, 1);
            }
        }

        var getOrderItems = function(){
            return orderItems;
        }

        var addItemsToReturn = function(item){
            itemsToReturn.push(item);
        }

        var removeItemsToReturn = function(item){
            var arrIndex = itemsToReturn.indexOf(item);
            if (arrIndex >= 0) {
                itemsToReturn.splice(arrIndex, 1);
            }
        }

        var getItemsToReturn = function(){
            return itemsToReturn;
        }

        var displayRowToItemsReturnTable = function(orderItem){
            
            var tblBodyItemsToReturn = $("#removing_return_items tbody");
            if (orderItem != '') {
                var newItemsToReturnRow = "";
                newItemsToReturnRow += "<tr>";
                newItemsToReturnRow += "<input type='hidden' name='return_items_order_article_id[]' class='form-control order_item_id' value='"+orderItem.id+"'>";
                newItemsToReturnRow += "<td>";
                newItemsToReturnRow += "<button type='button' class='btn btn-outline-danger remove_return_item'>";
                newItemsToReturnRow += "<i class='bi bi-arrow-left-square-fill'></i>Remove return item";
                newItemsToReturnRow += "</button>";
                newItemsToReturnRow += "</td>";
                newItemsToReturnRow += "<td>"+orderItem.article_id+"</td>";
                newItemsToReturnRow += "<td>"+orderItem.sku_name+"</td>";
                newItemsToReturnRow += "<td>";
                newItemsToReturnRow += "<input type='text' name='return_usable[]' id='ru"+orderItem.article_id+"' class='form-control inp-fixed-width' value='"+orderItem.quantity+"' readonly>";
                newItemsToReturnRow += "</td>";
                newItemsToReturnRow += "<td>";
                newItemsToReturnRow += "<input type='text' data-total-quantity='"+orderItem.quantity+"' name='return_no_usable[]' id='rnu"+orderItem.article_id+"' pattern='^[0-9]+$' class='form-control quantity-no-usable' value='0'>";
                newItemsToReturnRow += "</td>";
                newItemsToReturnRow += "<td>";
                newItemsToReturnRow += "<input type='text' name='short_comment[]' class='form-control'>";
                newItemsToReturnRow += "</td>";
                newItemsToReturnRow += "</tr>";
                tblBodyItemsToReturn.append(newItemsToReturnRow);
            }
        }

        var displayRowToOrderItemsTable = function(orderItem){
            var tblBodyItemsToReturn = $("#adding_return_items tbody");
            if (orderItem != '') {
                var newItemsToReturnRow = "";
                newItemsToReturnRow += "<tr>";
                newItemsToReturnRow += "<input type='hidden' name='order_item["+orderItem.id+"][id]' class='form-control order_item_id' value='"+orderItem.id+"'>";
                newItemsToReturnRow += "<td>"+orderItem.article_id+"</td>";
                newItemsToReturnRow += "<td>"+orderItem.sku_name+"</td>";
                newItemsToReturnRow += "<td>"+orderItem.quantity+"</td>";
                newItemsToReturnRow += "<td>";
                newItemsToReturnRow += "<button type='button' class='btn btn-outline-success add_return_item'>";
                newItemsToReturnRow += "Add return item<i class='bi bi-arrow-right-square-fill'></i>";
                newItemsToReturnRow += "</button>";
                newItemsToReturnRow += "</td>";
                newItemsToReturnRow += "</tr>";
                tblBodyItemsToReturn.append(newItemsToReturnRow);
            }

        }

        var loadOrderItemData = function(orderItemId){

            // let data = {
            //     orderItemId: orderItemId
            // }

            $.ajax({
                url: '/api/office/order_items/'+orderItemId,
                method: 'GET',
                async: false,
                // data: data,
                //dataType: 'json',
                success:function(response, status, xhr){
                    if(status === 'success' && xhr.readyState === 4){
                        // console.log(response);
                        returnItemsMoving.orderItem = response;

                        //orderItem = response;
                    }
                },
                error: function(xhr){
                    if(xhr.status === 401){
                        window.location.reload(true);
                    }else{
                        console.log(xhr.status+' '+xhr.statusText);

                    }
                }
            });//end ajax

        }

        var myEvent = function(){
            let total_quantity = this.getAttribute('data-total-quantity');
            let rnu_value = 0;
            let ru_value = total_quantity;

            let input_rnu_value = parseInt(this.value, 10);
            if (isNaN(input_rnu_value)) {
                rnu_value = 0;
            } else if (input_rnu_value > total_quantity) {
                rnu_value = total_quantity;
            } else if (input_rnu_value == ''){
                rnu_value = 0;
            } else {
                rnu_value = input_rnu_value;
            }

            ru_value -= rnu_value;

            let article_id = this.id.replace('rnu', '');
            document.getElementById('rnu' + article_id).value = rnu_value;
            document.getElementById('ru' + article_id).value = ru_value;
        };

        var removeHandler = function() {
            let my_input_fields = document.getElementsByClassName('quantity-no-usable');
            for(single_input of my_input_fields){
                single_input.removeEventListener('keyup', myEvent);
            }
        }

        var createEventsForQuantityNoUsable = function(){
            let my_input_fields = document.getElementsByClassName('quantity-no-usable');

            for(single_input of my_input_fields){
                single_input.addEventListener('keyup', myEvent)
            }
        };

        return {
            addOrderItem: addOrderItem,
            removeOrderItem: removeOrderItem,
            addItemsToReturn: addItemsToReturn,
            removeItemsToReturn: removeItemsToReturn,
            getOrderItems: getOrderItems,
            getItemsToReturn: getItemsToReturn,
            loadOrderItemData: loadOrderItemData,
            orderItem: orderItem,
            displayRowToItemsReturnTable, displayRowToItemsReturnTable,
            displayRowToOrderItemsTable, displayRowToOrderItemsTable,
            createEventsForQuantityNoUsable: createEventsForQuantityNoUsable,
            removeHandler: removeHandler,
            // removeEventsForQuantityNoUsable,
        };

    })();
    // console.log(window.location.pathname);
    if (window.location.pathname.indexOf('dashboard/returns')) {
        if (document.body.contains(document.getElementById('btn-edit-return'))) {
            // console.log(returnItemsMoving);
            
            returnItemsMoving.createEventsForQuantityNoUsable();
          }
    }
});