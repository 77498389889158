$('#filter_btn').on('click', click_fileter_btn);

function click_fileter_btn() {
	let href = '?page=1';
	$('.filter_search').each(function(key, value) {
		if ($(value).val() != '') {
			href += '&' + value.id.replace('filter_by_', '') + '=' + $(value).val();
		}
	});

	window.location.replace(href);
}

$('body').on('keyup', function(e) {
	if (e.key === 'Enter' || e.keyCode === 13) {
		if (document.getElementById("filter_btn") && $('.filter_search').is(":focus")) {
			click_fileter_btn();
		}
		if(document.getElementsByClassName("searchByColumn") && $(e.target).is(":focus")) {
			$(e.target).closest("form.searchByColumn").submit();
		}
	}
});