// type="button"
// id="create_document_merge_releases_btn"
// class="btn btn-info"
// data-bs-toggle="modal"
// data-bs-target="#create_document_merge_releases_modal"

$('#create_document_merge_releases_btn').on('click', function() {
	let my_data = {};
	my_data.method = 'ten_last_releases_from_project_id';
	my_data.post_data = {};
	my_data.post_data.project_id = $('#create_document_merge_releases_btn').attr('data-project_id');
	
	$.ajax({
		url: "/api/api_front_end_helper",
		method: "POST",
		data: my_data
	})
	.done(function(result) {
		// console.log(result);
		let res = JSON.parse(result);
		// console.log(res);
		create_document_merge_releases_modal_function(res.data);
	})
	.fail(function(result) {
		// console.log(result);
	});
});

function create_document_merge_releases_modal_function(data) {
	let chosen_release = $('#create_document_merge_releases_btn').attr('data-chosen_release');
	let array_already_clicked = [];
	$.each($('#div_for_adding_hidden_input_fields_via_js').children(), function(key, value) {
		array_already_clicked.push($(value).val());
	});
	let db_releases = data;
	
	$('#create_document_merge_releases_modal_wrapper').html('');
	
	let string = '';
	string += '<div ';
		string += 'class="modal fade" ';
		string += 'id="create_document_merge_releases_modal" ';
		string += 'tabindex="-1" ';
		string += 'aria-labelledby="create_document_merge_releases_modal_label" ';
		string += 'aria-hidden="true" ';
	string += '> ';
		string += '<div class="modal-dialog modal-xl"> ';
			string += '<div class="modal-content"> ';
				string += '<div class="modal-header"> ';
					string += '<h6 ';
						string += 'class="modal-title" ';
						string += 'id="create_document_merge_releases_modal_label" ';
					string += '> ';
						string += 'Choose releases to merge for document creation ';
					string += '</h6> ';

					string += '<button ';
						string += 'type="button" ';
						string += 'class="btn-close" ';
						string += 'data-bs-dismiss="modal" ';
						string += 'aria-label="Close" ';
					string += '></button> ';
				string += '</div> ';
				
				string += '<div class="modal-body"> ';
						string += '<table class="table table-hover"> ';
							string += '<thead> ';
								string += '<tr> ';
									string += '<th class="text-center">Date</th> ';
									string += '<th class="text-center">Detailed report</th> ';
									string += '<th class="text-center"></th> ';
								string += '</tr> ';
							string += '</thead> ';

							string += '<tbody> ';
							$.each(db_releases, function(key, value) {
								string += '<tr> ';
									string += '<td class="text-center">' + value.created + '</td> ';
									string += '<td class="text-center">' + value.report + '</td> ';
									string += '<td class="text-center"> ';
										string += '<input ';
											string += 'type="checkbox" ';
											string += 'class="modal_release_id" ';
											string += 'value="' + value.id + '" ';
											if (value.id == chosen_release) {
												string += 'checked ';
												string += 'disabled ';
											}
											if (array_already_clicked.indexOf(value.id.toString()) !== -1) {
												string += 'checked ';
											}
										string += '> ';
									string += '</td> ';
								string += '</tr> ';
							});
							string += '</tbody> ';
						string += '</table> ';
				string += '</div> ';
				
				string += '<div class="modal-footer"></div> ';
			string += '</div> ';
		string += '</div> ';
	string += '</div> ';
	$('#create_document_merge_releases_modal_wrapper').html(string);

	let my_modal = new bootstrap.Modal(document.getElementById('create_document_merge_releases_modal'), {
	  keyboard: false
	});
	my_modal.show();

	$('.modal_release_id').on('change', function() {
		let tmp_release_id = $(this).val();
		let tmp_checked = $(this).prop('checked');

		let tmp_form = $('#create_document_form');
		// let tmp_div = $('#div_for_adding_hidden_input_fields_via_js');
		let tmp_string = '<input type="hidden" name="release_ids[]" id="release_id_input_' + tmp_release_id + '" value="' + tmp_release_id + '">';
		
		if (!tmp_checked) { // checkbox is uncheced, out of #div_for_adding_hidden_input_fields_via_js
			$("#release_id_input_" + tmp_release_id + "").remove();
		} else { // checkbox is checked, into #div_for_adding_hidden_input_fields_via_js
			tmp_form.append(tmp_string);
			// tmp_div.append(tmp_string);
		}
	});
}