$('.delete_btn').on('click', function(e) {
	e.preventDefault();

	if (confirm('Are you sure?')) {
		$(this).closest('form').submit();
	}

	return false;
});

$('#my_login_form').on('keyup', function(e) {
	if (e.keyCode == 13) {
		$('#my_login_form').submit();
	}
});
