let orders = document.getElementsByClassName('view_one_order_on_click_class');

for(let one of orders) {
    one.addEventListener('click', one_order_view_listener);
}

function one_order_view_listener(e) {
    let order_id = e.currentTarget.getAttribute('data-order_id');

    const from_server = async () => {
        const response = await fetch(
            "/api/office/orders/get_one_order?id=" + order_id, {
                method: 'get',
                headers: {
                    "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    "X-Requested-With" : "XMLHttpRequest"
                }
            }
        );
        const data = await response.json();

        return data;
    };

	function orderStatusColor(data){
		if(data === 'missing'){
			return '<span><strong>Order Status: </strong></span> <span style="color:red;">' + data + '</span> <br>';
		}else{
			return '<span><strong>Order Status: </strong></span> <span>' + data + '</span> <br>';
		}
	}

	function listOrders(order, status, warehouse) {
        style = "";
        elements = "";
        const orderWh = order.map((t1) => ({
            ...warehouse.find((t2) => t2.article_id === t1.article_id),
            ...t1,
        }));
        let temp_wh_quantity = [];
        orderWh.map((item) => {
            if (typeof temp_wh_quantity[item.article_id] === "undefined") {
                temp_wh_quantity[item.article_id] = item.wh_quantity;
            }
            temp_wh_quantity[item.article_id] -= item.quantity;
            if (status === 'missing' && temp_wh_quantity[item.article_id] < 0) {
                style = 'style="color:red;"';
            } else {
                style = "";
            }

            let sku = item.article ? item.article.sku : item.sku_name;
            elements += `<tr>
			<td ${style}>${sku}</td>
			<td ${style}>${item.quantity}</td>
			</tr>`;
        });

        return elements;
    }

	function parcelPreformPhaseDisplay(data){
		try {
			if(typeof JSON.parse(data.pp_event[data.pp_event.length -1].data).event === 'undefined'){
				return 'no Data'
			}
			let parcelPreformEvent = JSON.parse(data.pp_event[data.pp_event.length -1].data);
			switch (parcelPreformEvent.phase) {
				case 'Delivery':
					parcelPreformEvent.phase = 'Delivered';
					break;
				default:
					parcelPreformEvent.phase = 'In Transit';
					break;
			}

			return parcelPreformEvent.phase;
		} catch (error) {
			return 'no data found';
		}
	}

    from_server().then(data => {
        console.log(data);
        let d = data[0][0];
		let w = data[1];
        let courierName = d.courier === null ? 'no data' : d.courier.name
        // $('#voom_labelledby').html('');
        $('#view_one_order_modal_body').html('');
        let string = '';
        string += '<div class="row">';
			string += '<div>';
			string += '<span><strong>Order Reference: </strong></span>';
			string += '<span>' + d.reference + '</span>';
			string += '<br>';
			string +=  orderStatusColor(d.status);
			string += '<span><strong>Courier: </strong></span>';
			string += '<span>' + courierName + '</span>';
			string += '<br>';
			string += '<span><strong>Parcel Status: </strong></span>';
			string += '<span>' + parcelPreformPhaseDisplay(d) + '</span>';
			string += '<br>';
			string += '<table class="table table-bordered table-striped mt-2" style="font-size: 0.8rem;">';
			string += '<thead class="thead_listOrderTable">';
			string += '<tr>';
			string += '<th>SKU</th>';
			string += '<th style="width: 20%;">Quantity</th>';
			string += '</tr>';
			string += '</thead>';
			string += '<tbody>'
			string += listOrders(d.articles, d.status, w);
			string += '</tbody>'
			string += '</table>'
			string += '</div>';
            string += '<div class="col-md-6 text-start">';
                string += '<h5 class="text-center">Buyer</h5>';
                string += '<hr>';
                string += '<span><strong>Full Name: </strong></span>';
                string += '<span>' + d.billing_full_name + '</span>';
                string += '<br>';

                string += '<span><strong>Address: </strong></span>';
                string += '<span>' + d.billing_address_1 + '</span>';
                string += '<br>';

                string += '<span><strong>City: </strong></span>';
                string += '<span>' + d.billing_city + '</span>';
                string += '<br>';

                string += '<span><strong>Postal Code: </strong></span>';
                string += '<span>' + d.billing_zip + '</span>';
                string += '<br>';

                string += '<span><strong>Province: </strong></span>';
                string += '<span>' + d.billing_province + '</span>';
                string += '<br>';

                string += '<span><strong>Country: </strong></span>';
                string += '<span>' + d.billing_country_code + '</span>';
                string += '<br>';

                string += '<span><strong>Phone: </strong></span>';
                string += '<span>' + d.billing_phone + '</span>';
                string += '<br>';

                string += '<span><strong>Email: </strong></span>';
                string += '<span>' + d.billing_email + '</span>';
                string += '<br>';
            string += '</div>';

            string += '<div class="col-md-6 text-start">';
                string += '<h5 class="text-center">Receiver</h5>';
				string += '<hr>';
                string += '<span><strong>Full Name: </strong></span>';
                string += '<span>' + d.shipping_full_name + '</span>';
                string += '<br>';

                string += '<span><strong>Address: </strong></span>';
                string += '<span>' + d.shipping_address_1 + '</span>';
                string += '<br>';

                string += '<span><strong>City: </strong></span>';
                string += '<span>' + d.shipping_city + '</span>';
                string += '<br>';

                string += '<span><strong>Postal Code: </strong></span>';
                string += '<span>' + d.shipping_zip + '</span>';
                string += '<br>';

                string += '<span><strong>Province: </strong></span>';
                string += '<span>' + d.shipping_province + '</span>';
                string += '<br>';

                string += '<span><strong>Country: </strong></span>';
                string += '<span>' + d.shipping_country_code + '</span>';
                string += '<br>';

                string += '<span><strong>Phone: </strong></span>';
                string += '<span>' + d.shipping_phone + '</span>';
                string += '<br>';

                string += '<span><strong>Email: </strong></span>';
                string += '<span>' + d.shipping_email + '</span>';
                string += '<br>';
            string += '</div>';
        string += '</div>';

        $('#view_one_order_modal_body').append(string);


        $('#view_one_order_modal').modal('show');
    });
}
