$(document).ready(function(){ 
    if (document.body.contains(document.getElementById('folders'))) {
        var currentProject = $( "#folders" ).attr("data-project-name");
        var currentDirPath = $( "#folders" ).attr("data-path");

        function getLastDirNameFromURL(dirPath) {
            currDirPath = dirPath.replace(/\\/g, "/");
            var arrayPathParts = currDirPath.split('/');
            var lastFolderInURL = arrayPathParts.splice(-1);
            return lastFolderInURL[0];
        }

        function showOrHideExitButton(lastFolderInURL) {
            // console.log({last:lastFolderInURL, current:currentProject});
            if (lastFolderInURL == currentProject) {
                if(!$( ".exitFolderButton" ).hasClass('d-none')){
                    $( ".exitFolderButton" ).addClass('d-none');
                }
            } else {            
                $( ".exitFolderButton" ).removeClass('d-none');
            } 
        }

        var lastFolderInURL = getLastDirNameFromURL(currentDirPath);
        showOrHideExitButton(lastFolderInURL);


        $(document).on('click', '.folder', function(event){
            var clickedFolderPath = event.target.id; 

            var lastFolderInURL = getLastDirNameFromURL(clickedFolderPath);
            
            $.ajax({
                url: '/dashboard/download',
                method: 'POST', 
                cache: false,
                crossDomain: false,
                data: {
                    path: clickedFolderPath,
                },
                success:function(result) {    
                    $('#folders').html('');  
                    $( ".noResultsMessage" ).remove();                   
                    $('#folders').attr('data-path', result.currentDir);
                    var html = '';
                    for(var i=0; i<result.data.length; i++) {
                        if(typeof(result.data[i].filePath) != "undefined" && result.data[i].filePath !== null) {                        
                            html += '<div class="fileWrapper order-2 mt-5 p-3 bg-gradient bg-opacity-10 shadow-sm">'+
                                        '<a class="file bi bi-download float-start h5" href="'+result.data[i].filePath+'" id="'+result.data[i].fileId+'" download>'+result.data[i].fileName+'</a>'+
                                        '<span class="date float-end">'+result.data[i].formatedFileDate+'</span>'+
                                    '</div>';
                        } else {                        
                            html += '<div class="w-25 order-1 cursor-pointer">'+
                                        '<div class="folder bi bi-folder-fill display-1" id="'+result.data[i].dirPath+'"></div>'+
                                        '<div class="h5">'+
                                        result.data[i].dirName+
                                        '</div>'+
                                    '</div>';
                        }
                    }

                    showOrHideExitButton(lastFolderInURL); 
                    $('#folders').append(html);                     
                },
                error:function(xhr, status, error){
                    // console.log("Greska!");
                    // console.log(xhr);
                    // console.log(status);
                    // console.log(error);
                }
            });//end ajax
        });

        $(document).on('click', '.exitFolderButton', function(event){     
            var currentDirPath = $( "#folders" ).attr("data-path");                 
            currentDirPath = currentDirPath.replace(/\\/g, "/");
            var arrayPathParts = currentDirPath.split('/');
            arrayPathParts.splice(-1);
            var arrayPath = arrayPathParts.join('/');
            var lastFolderInURL = arrayPathParts.splice(-1);

            showOrHideExitButton(lastFolderInURL[0]);
            $.ajax({
                url: '/dashboard/download',
                method: 'POST', 
                cache: false,
                crossDomain: false,
                data: {
                    path: arrayPath,
                },
                success:function(result) {  
                    $('#folders').html('');    
                    $( ".noResultsMessage" ).remove();    
                    $('#folders').attr('data-path', result.currentDir);
                    var html = '';
                    for(var i=0; i<result.data.length; i++) {
                        if(typeof(result.data[i].filePath) != "undefined" && result.data[i].filePath !== null) {                        
                            html += '<div class="fileWrapper order-2 mt-5 p-3 bg-gradient bg-opacity-10 shadow-sm">'+
                                        '<a class="file bi bi-download float-start h5" href="'+result.data[i].filePath+'" id="'+result.data[i].fileId+'" download>'+result.data[i].fileName+'</a>'+
                                        '<span class="date float-end">'+result.data[i].formatedFileDate+'</span>'+
                                    '</div>';
                        } else {                        
                            html += '<div class="w-25 order-1 cursor-pointer">'+
                                        '<div class="folder bi bi-folder-fill display-1" id="'+result.data[i].dirPath+'"></div>'+
                                        '<div class="h5">'+
                                        result.data[i].dirName+
                                        '</div>'+
                                    '</div>';
                        }
                    }
                    $('#folders').append(html);
                },
                error:function(xhr, status, error){
                    console.log("Greska!");
                    console.log(xhr);
                    console.log(status);
                    console.log(error);
                }
            });//end ajax
        });

        $(document).on('click', '#searchByIdButton', function(event){
            event.preventDefault(); 
            if ($( "#releaseId" ).val()) {
                var releaseId = $( "#releaseId" ).val();
                var currentDirPath = $( "#folders" ).attr("data-path");                 

                $.ajax({
                    url: '/dashboard/download',
                    method: 'POST', 
                    cache: false,
                    crossDomain: false,
                    data: {
                        releaseId: releaseId,
                        currentPath: currentDirPath
                    },
                    success:function(result) { 
                        $('#folders').html('');
                        $( ".noResultsMessage" ).remove(); 
                        var html = '';
                        var tmp = false;
                        var LastFolderInURL = currentProject;

                        for(var i=0; i<result.data.length; i++) {
                            if (result.message != '') {
                                LastFolderInURL = getLastDirNameFromURL (currentDirPath);
                                $('#folders').attr('data-path', currentDirPath);

                                if($('.noResultsMessage').text()==''){
                                    tmp = true;
                                }
                            } 
                            else {
                                var resultDirPath = result.data[i].fileId;                 
                                resultDirPath = resultDirPath.replace(/\\/g, "/");
                                var pathParts = resultDirPath.split('/');
                                LastFolderInURL = pathParts.splice(-1);
                                LastFolderInURL = LastFolderInURL[0];
                                var oneDirBeforeCurrent = pathParts.join('/');
                                
                                if(result.currentDir != '') {
                                    $('#folders').attr('data-path', result.currentDir);
                                } else {
                                    $('#folders').attr('data-path', oneDirBeforeCurrent);
                                }
                            }

                            if(typeof(result.data[i].filePath) != "undefined" && result.data[i].filePath !== null) {                          
                                html += '<div class="fileWrapper order-2 mt-5 p-3 bg-gradient bg-opacity-10 shadow-sm">'+
                                            '<a class="file bi bi-download float-start h5" href="'+result.data[i].filePath+'" id="'+result.data[i].fileId+'" download>'+result.data[i].fileName+'</a>'+
                                            '<span class="date float-end">'+result.data[i].formatedFileDate+'</span>'+
                                        '</div>';
                            } else {                     
                                html += '<div class="w-25 order-1 cursor-pointer">'+
                                            '<div class="folder bi bi-folder-fill display-1" id="'+result.data[i].dirPath+'"></div>'+
                                            '<div class="h5">'+
                                            result.data[i].dirName+
                                            '</div>'+
                                        '</div>';
                            }
                        }
                        if(tmp) {
                            $('.forms').append('<div class="noResultsMessage">'+result.message+'</div>');
                        }

                        showOrHideExitButton(LastFolderInURL);
                        $('#folders').append(html);
                    },
                    error:function(xhr, status, error){
                        console.log("Greska!");
                        console.log(xhr);
                        console.log(status);
                        console.log(error);
                    }
                });//end ajax
            }
            
        });

        $(document).on('click', '#goToCurrentDateButton', function(event){
            event.preventDefault(); 

            var currentDate = $( "#currentDate" ).val();
            $.ajax({
                url: '/dashboard/download',
                method: 'POST', 
                cache: false,
                crossDomain: false,
                data: {
                    currentDate: currentDate,
                },
                success:function(result) {  
                    $('#folders').html('');  
                    $( ".noResultsMessage" ).remove();       
                    $('#folders').attr('data-path', result.currentDir);
                    var html = '';

                    for(var i=0; i<result.data.length; i++) {
                        if(typeof(result.data[i].filePath) != "undefined" && result.data[i].filePath !== null) {                                                                
                            html += '<div class="fileWrapper order-2 mt-5 p-3 bg-gradient bg-opacity-10 shadow-sm">'+
                                        '<a class="file bi bi-download float-start h5" href="'+result.data[i].filePath+'" id="'+result.data[i].fileId+'" download>'+result.data[i].fileName+'</a>'+
                                        '<span class="date float-end">'+result.data[i].formatedFileDate+'</span>'+
                                    '</div>';
                        } else {                        
                            html += '<div class="w-25 order-1 cursor-pointer">'+
                                        '<div class="folder bi bi-folder-fill display-1" id="'+result.data[i].dirPath+'"></div>'+
                                        '<div class="h5">'+
                                        result.data[i].dirName+
                                        '</div>'+
                                    '</div>';
                        }
                    }
                    var currentDirPath = result.currentDir.replace(/\\/g, "/");
                    var lastFolderInURL = getLastDirNameFromURL(currentDirPath);
    
                    showOrHideExitButton(lastFolderInURL);
                    $('#folders').append(html);
                },
                error:function(xhr, status, error){
                    console.log("Greska!");
                    console.log(xhr);
                    console.log(status);
                    console.log(error);
                }
            });//end ajax
        });
    }
});

