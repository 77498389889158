window.addEventListener('load', function () {
    $(function(){
        if($('body').has('.ordersMain')){
            let splitBtns = [];
            splitBtns = document.getElementsByClassName('manualSplitOrder');
            let manualSplitOrderInput = document.querySelector('#passId');

            Array.from(splitBtns).forEach(btn => {
                btn.addEventListener('click',function(e){
                    manualSplitOrderInput.value = e.currentTarget.getAttribute('data-id-value')
                })
            })

        }
    });
});


