if (document.body.contains(document.getElementById('order_edit_total_weigh_js_cals'))) {
    let number_of_rows = document.getElementsByClassName('edit_order_row_number_js_value').length;

    let all_quantities_cells = document.getElementsByClassName('edit_order_quantity_js_value');
    let all_weights_cells = document.getElementsByClassName('edit_order_weight_js_value');

    set_order_edit_total_weight_value(number_of_rows, all_quantities_cells, all_weights_cells);

    for (q of all_quantities_cells) {
        q.addEventListener('change', function() {
            let all_quantities_cells = document.getElementsByClassName('edit_order_quantity_js_value');
            let all_weights_cells = document.getElementsByClassName('edit_order_weight_js_value');

            set_order_edit_total_weight_value(number_of_rows, all_quantities_cells, all_weights_cells);
        })
    }
}
function set_order_edit_total_weight_value(number_of_rows, all_quantities_cells, all_weights_cells) {
    let total_weight = 0;
    for (let i = 0; i < number_of_rows; i++) {
        total_weight += +all_quantities_cells[i].value * +all_weights_cells[i].value;
    }

    document.getElementById('order_edit_total_weigh_js_cals').innerHTML = total_weight.toFixed(3);
}
window.addEventListener('load', function () {
    $(function(){
        if(document.querySelector(".order__edit-update")){
            const deleteBtn = document.querySelectorAll('.delete__order-article');
            const submitBtn = document.querySelector('.list_of_order_article_submit');
            const submitBtnClone = document.querySelector('.list_of_order_article_submit_clone');
            // made for visualy moving button to bottom of page because of form structure is not possible
            deleteBtn.forEach(btn => {
                btn.addEventListener('click', (e)=>{
                    deleteOrderArticle(e.target);
                })
            });

            let arrayOfItemsToDelete = [];

            let iputElement = document.querySelector(
                "#list_of_order_article_to_delete"
            );

            function deleteOrderArticle(element) {
                let wholeElement = element.closest('tr');
                arrayOfItemsToDelete.push(
                    wholeElement.querySelector("input").value
                );
                wholeElement.remove();
                iputElement.value = JSON.stringify(arrayOfItemsToDelete);
            }

            submitBtnClone.addEventListener('click', ()=> submitBtn.click());

        }
    });
});
