$(document).ready(function(){
    if (document.body.contains(document.getElementsByClassName('client_upload_form_li_skus')[0])) {
        $(document).on('keyup', '.client_upload_form_li_skus', function(event){
            //var sku_field_id = event.target.id;
            var sku_field_value = $(this).val();

            if (sku_field_value.length < 3) {
                return false;
            }

            $.ajax({
                url: '/product',
                method: 'POST', 
                cache: false,
                crossDomain: false,
                data: {
                    sku: sku_field_value,
                },
                success:function(result) {    
                    var html = '';
                    $( ".client_upload_form_li_skus + datalist" ).remove();
                    
                    html += '<datalist id="datalistOptions">';
                    for(var i=0; i<result.data.length; i++) {
                        html += '<option value="' + result.data[i].sku + '">';
                    }    
                    html += '</datalist>';   
                    $( html ).insertAfter( ".client_upload_form_li_skus" );               
                },
                error:function(xhr, status, error){
                    // console.log("Greska!");
                    // console.log(xhr);
                    // console.log(status);
                    // console.log(error);
                }
            });//end ajax
        });            
    }
});

